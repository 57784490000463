const data = {
  "categories": [
  {
    "title": "Proxmox VE Tools",
  }, {
    "title": "Home Assistant",
  }, {
    "title": "Automation",
  }, {
    "title": "MQTT",
  }, {
    "title": "Database",
  }, {
    "title": "Zigbee - Zwave - Matter",
  }, {
    "title": "Monitoring - Analytics",
  }, {
    "title": "Docker - Kubernetes",
  }, {
    "title": "Operating System",
  }, {
    "title": "TurnKey",
  }, {
    "title": "Server - Networking",
  }, {
    "title": "Media - Photo",
  }, {
    "title": "NVR - DVR",
  }, {
    "title": "Ad Blocker - DNS",
  }, {
    "title": "Document - Notes",
  }, {
    "title": "Dashboards",
  }, {
    "title": "File - Code",
  }, {
    "title": "Miscellaneous",
  }],
  "items": [
/* Proxmox VE Tools */
  {
    "category": "Proxmox VE Tools",
    "title": "Proxmox VE Post Install",
    "content": `<p align="center"><img src="https://github.com/home-assistant/brands/blob/master/core_integrations/proxmoxve/icon.png?raw=true" height="100"/></p>
   
  <h1 align="center" id="heading"> Proxmox VE Post Install </h1>
  
  This script provides options for managing Proxmox VE repositories, including disabling the Enterprise Repo, adding or correcting PVE sources, enabling the No-Subscription Repo, adding the test Repo, disabling the subscription nag, updating Proxmox VE, and rebooting the system.
    
  Run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/misc/post-pve-install.sh)"
  ~~~
  
  It is recommended to answer “yes” (y) to all options presented during the process.`
  },
  {
    "category": "Proxmox VE Tools",

    "title": "Proxmox VE Kernel Clean",
    "content": `<p align="center"><img src="https://github.com/home-assistant/brands/blob/master/core_integrations/proxmoxve/icon.png?raw=true" height="100"/></p>
  
  <h1 align="center" id="heading">Proxmox VE Kernel Clean </h1>
  Cleaning unused kernel images is beneficial for reducing the length of the GRUB menu and freeing up disk space. By removing old, unused kernels, the system is able to conserve disk space and streamline the boot process.
      
  Run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/misc/kernel-clean.sh)"
  ~~~`
  },
  {
    "category": "Proxmox VE Tools",

    "title": "Proxmox VE Kernel Pin",
    "content": `<p align="center"><img src="https://github.com/home-assistant/brands/blob/master/core_integrations/proxmoxve/icon.png?raw=true" height="100"/></p>
  
  <h1 align="center" id="heading">Proxmox VE Kernel Pin </h1>
  Kernel Pin is an essential tool for effortlessly managing kernel pinning and unpinning.
      
  Run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/misc/kernel-pin.sh)"
  ~~~`
  },
  {
    "category": "Proxmox VE Tools",

    "title": "Proxmox VE Processor Microcode",
    "content": `<p align="center"><img src="https://github.com/tteck/Proxmox/blob/main/misc/images/processor.png?raw=true" height="100"/></p>
  
  <h1 align="center" id="heading"> Proxmox VE Processor Microcode </h1>
  <h3 align="center"> For Intel or AMD Processors </h3>
  
  Processor Microcode is a layer of low-level software that runs on the processor and provides patches or updates to its firmware. Microcode updates can fix hardware bugs, improve performance, and enhance security features of the processor.

  It's important to note that the availability of firmware update mechanisms, such as Intel's Management Engine (ME) or AMD's Platform Security Processor (PSP), may vary depending on the processor and its specific implementation. Therefore, it's recommended to consult the documentation for your processor to confirm whether firmware updates can be applied through the operating system.  
  
  Run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/misc/microcode.sh)"
  ~~~
  

  After a reboot, you can check whether any microcode updates are currently in effect by running the following command.

  ~~~bash
  journalctl -k | grep -E "microcode" | head -n 1
  ~~~`
  },
  {
    "category": "Proxmox VE Tools",

    "title": "Proxmox VE CPU Scaling Governor",
    "content": `<p align="center"><img src="https://github.com/tteck/Proxmox/blob/main/misc/images/cpu.png?raw=true" height="100"/></p>
  
  <h1 align="center" id="heading">Proxmox VE CPU Scaling Governor </h1>

  The CPU scaling governor determines how the CPU frequency is adjusted based on the workload, with the goal of either conserving power or improving performance. By scaling the frequency up or down, the operating system can optimize the CPU usage and conserve energy when possible. [Generic Scaling Governors](https://www.kernel.org/doc/html/latest/admin-guide/pm/cpufreq.html?#generic-scaling-governors)
  
  Run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/misc/scaling-governor.sh)"
  ~~~`
  },
  {
    "category": "Proxmox VE Tools",

    "title": "Proxmox VE LXC Filesystem Trim",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/lxc.svg" height="100"/></p>
  
  <h1 align="center" id="heading">Proxmox VE LXC Filesystem Trim </h1>
  This maintains SSD performance by managing unused blocks. Thin-provisioned storage systems also require management to prevent unnecessary storage use. VMs automate fstrim, while LXC containers need manual or automated fstrim processes for optimal performance.
  
  This is designed to work with SSDs on ext4 filesystems only. <a href="https://github.com/tteck/Proxmox/discussions/2505#discussion-6226037" target="_blank" rel="noopener noreferrer">More Info</a>

  Run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/misc/fstrim.sh)"
  ~~~`
  },
  {
    "category": "Proxmox VE Tools",

    "title": "Proxmox VE LXC Cleaner",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/lxc.svg" height="100"/></p>
  
  <h1 align="center" id="heading">Proxmox VE LXC Cleaner </h1>
  This script provides options to delete logs and cache, and repopulate apt lists for Ubuntu and Debian systems.
   
  Run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/misc/clean-lxcs.sh)"
  ~~~`
  },
  {
    "category": "Proxmox VE Tools",

    "title": "Proxmox VE LXC Updater",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/lxc.svg" height="100"/></p>
  
  <h1 align="center" id="heading">Proxmox VE LXC Updater </h1>
  This script has been created to simplify and speed up the process of updating all LXC containers across various Linux distributions, such as Ubuntu, Debian, Devuan, Alpine Linux, CentOS-Rocky-Alma, Fedora, and ArchLinux. It's designed to automatically skip templates and specific containers during the update, enhancing its convenience and usability.<br> 
  
  Run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/misc/update-lxcs.sh)"
  ~~~`
  },
  {
    "category": "Proxmox VE Tools",

    "title": "Proxmox VE Cron LXC Updater",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/lxc.svg" height="100"/></p>
  
  <h1 align="center" id="heading">Proxmox VE Cron LXC Updater </h1>
  This script will add/remove a crontab schedule that updates all LXCs every Sunday at midnight.<br> 
  
  To exclude LXCs from updating, edit crontab (<code class="highlighter-rouge">crontab -e</code>) and add CTID as shown in the example (-s 103 111)

  example: <code class="highlighter-rouge">0 0 * * 0 PATH=/usr/local/sbin:/usr/local/bin:/usr/sbin:/usr/bin:/sbin:/bin /bin/bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/misc/update-lxcs-cron.sh)" -s 103 111 >>/var/log/update-lxcs-cron.log 2>/dev/null</code>
  
  Run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/misc/cron-update-lxcs.sh)"
  ~~~`
  },
  {
    "category": "Proxmox VE Tools",

    "title": "Proxmox VE Monitor-All",
    "content": `<p align="center"><img src="https://github.com/tteck/Proxmox/blob/main/misc/images/monitor.png?raw=true" height="100"/></p>
  
  <h1 align="center" id="heading">Proxmox VE Monitor-All </h1>
  This script will add Monitor-All to Proxmox VE, which will monitor the status of all your instances, both containers and virtual machines, excluding templates and user-defined ones, and automatically restart or reset them if they become unresponsive. This is particularly useful if you're experiencing problems with Home Assistant becoming non-responsive every few days/weeks. Monitor-All also maintains a log of the entire process, which can be helpful for troubleshooting and monitoring purposes.<br>
  <br>
  <h4>🛈 Virtual machines without the QEMU guest agent installed must be excluded.</h4>
  <h4>🛈 Prior to generating any new CT/VM not found in this repository, it's necessary to halt Proxmox VE Monitor-All by running <code class="highlighter-rouge">systemctl stop ping-instances</code>.</h4>
  
  All commands are run from the <b>Proxmox VE shell.</b>.
  
  <b>To add or remove Monitor-All in Proxmox VE:</b>
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/misc/monitor-all.sh)"
  ~~~
  <br>
  <br>
  To make setup changes, first stop the service: <code class="highlighter-rouge">systemctl stop ping-instances</code>

  To edit pause time: 
  ~~~bash
  nano /usr/local/bin/ping-instances.sh
  ~~~

  To add excluded instances:
  ~~~bash
  nano /etc/systemd/system/ping-instances.service
  ~~~
  
  After changes have been saved, <code class="highlighter-rouge">systemctl daemon-reload</code> and start the service: <code class="highlighter-rouge">systemctl start ping-instances</code>

  Monitor-All logs : <code class="highlighter-rouge">cat /var/log/ping-instances.log</code>`
  },
  {
    "category": "Proxmox VE Tools",

    "title": "Proxmox VE Host Backup",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/bash-dark.svg" height="100"/></p>
  
  <h1 align="center" id="heading">Proxmox VE Host Backup </h1>
  This script serves as a versatile backup utility, enabling users to specify both the backup path and the directory they want to work in. This flexibility empowers users to select the specific files and directories they wish to back up, making it compatible with a wide range of hosts, not limited to Proxmox.
   
  Run the command below in the <b>Proxmox VE Shell</b>, or any host.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/misc/host-backup.sh)"
  ~~~
  
  A backup is rendered ineffective when it remains stored on the host`
  },
  /*{
    "category": "Proxmox VE Tools",

    "title": "Proxmox VE Dark Theme",
    "content": `<p align="center"><img src="https://camo.githubusercontent.com/f6f33a09f8c1207dfb3dc1cbd754c2f3393562c11b1c999751ad9a91a656834a/68747470733a2f2f692e696d6775722e636f6d2f536e6c437948462e706e67" height="100"/></p>
  
  <h1 align="center" id="heading"> Proxmox VE Discord Dark Theme </h1>
  
  A dark theme for the Proxmox VE Web UI is a custom theme created by [Weilbyte](https://github.com/Weilbyte/PVEDiscordDark) that changes the look and feel of the Proxmox web-based interface to a dark color scheme. This theme can improve the visual experience and make the interface easier on the eyes, especially when used in low-light environments.
  
  Run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash <(curl -s https://raw.githubusercontent.com/Weilbyte/PVEDiscordDark/master/PVEDiscordDark.sh ) install
  ~~~
  
  To uninstall the theme, simply run the script with the <code class="highlighter-rouge">uninstall</code> command.`
  },*/
  {
    "category": "Proxmox VE Tools",

    "title": "Proxmox Backup Server Post Install",
    "content": `<p align="center"><img src="https://github.com/home-assistant/brands/blob/master/core_integrations/proxmoxve/icon.png?raw=true" height="100"/></p>
  
  <h1 align="center" id="heading"> Proxmox Backup Server Post Install </h1>
  The script will give options to Disable the Enterprise Repo, Add/Correct PBS Sources, Enable the No-Subscription Repo, Add Test Repo, Disable Subscription Nag, Update Proxmox Backup Server and Reboot PBS.
  
  Run the command below in the <b>Proxmox Backup Server Shell</b>. ⚠️ **Proxmox Backup Server ONLY**
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/misc/post-pbs-install.sh)"
  ~~~
  
  It is recommended to answer “yes” (y) to all options presented during the process.`
  },
  {
    "category": "Proxmox VE Tools",

    "title": "Proxmox VE Netdata",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/netdata.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> Proxmox VE Netdata </h1>
  
  [Netdata](https://www.netdata.cloud/) is an open-source, real-time performance monitoring tool designed to provide insights into the performance and health of systems and applications. It is often used by system administrators, DevOps professionals, and developers to monitor and troubleshoot issues on servers and other devices.
  
  To install/uninstall Netdata on Proxmox VE, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/misc/netdata.sh)"
  ~~~
  
  **Netdata Interface: IP:19999**`
  
  },










/* Home Assistant */
  {
    "category": "Home Assistant",

    "title": "Home Assistant OS VM",
    "content": `<p align="center"><img src="https://github.com/tteck/Proxmox/blob/main/misc/images/haos.png?raw=true"/></p>
   
  <h1 align="center" id="heading"> Home Assistant OS VM </h1>
  <h3 align="center"> Option to create VM using Stable, Beta or Dev Image </h3>
  
  This script automates the process of creating a Virtual Machine (VM) using the official KVM (qcow2) disk image provided by the Home Assistant Team. It involves finding, downloading, and extracting the image, defining user-defined settings, importing and attaching the disk, setting the boot order, and starting the VM. It supports various storage types, and does not involve any hidden installations.

  <h4>🛈 The disk must have a minimum size of 32GB and its size cannot be changed during the creation of the VM.</h4>
  
  To create a new Proxmox VE Home Assistant OS VM, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/vm/haos-vm.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  4GB RAM - 32GB Storage - 2vCPU - Stable Image ⚡</h3>
  
  After the script completes, click on the VM, then on the **_Summary_** tab to find the VM IP.
  
  **Home Assistant Interface: IP:8123**`
  },
  {
    "category": "Home Assistant",

    "title": "PiMox HAOS VM",
    "content": `<p align="center"><img src="https://github.com/tteck/Proxmox/blob/main/misc/images/pimox.png?raw=true" width="100" height="100"/></p>
  <h1 align="center" id="heading"> PiMox HAOS VM </h1>
  
  <h3 align="center"> Option to create VM using Stable, Beta or Dev Image </h3>
  
  The script automates the manual process of finding, downloading and extracting the aarch64 (qcow2) disk image provided by the Home Assistant Team, creating a VM with user defined settings, importing and attaching the disk, setting the boot order and starting the VM.
  
  To create a new PiMox HAOS VM, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/vm/pimox-haos-vm.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  4GB RAM - 32GB Storage - 2vCPU - Stable Image ⚡</h3>
  
  After the script completes, click on the VM, then on the **_Console_** tab to find the VM IP.
  
  **Home Assistant Interface: IP:8123**`
  },
  {
    "category": "Home Assistant",

    "title": "Home Assistant Container LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/docker.svg" height="100"/>
  <img src="https://avatars.githubusercontent.com/u/13844975?s=200&amp;v=4" width="100" height="100"/><img src="https://avatars1.githubusercontent.com/u/22225832?s=400&amp;v=4" alt="GitHub - portainer/portainer-docs: Portainer documentation" width="100" height="100"/></p>
    
  <h1 align="center" id="heading"> Home Assistant Container LXC </h1>

  A standalone container-based installation of Home Assistant Core means that the software is installed inside a Docker container, separate from the host operating system. This allows for flexibility and scalability, as well as improved security, as the container can be easily moved or isolated from other processes on the host.
  
  <h4>🛈 If the LXC is created Privileged, the script will automatically set up USB passthrough.</h4>

  To create a new Proxmox VE Home Assistant Container LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Containers, Remove Images or Install HACS, run the command below (or type update) in the <b>LXC Console</b>.
    
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/homeassistant.sh)"
  ~~~
    
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 16GB Storage - 2vCPU ⚡</h3>
    
  **Home Assistant Interface: IP:8123**
    
  **Portainer Interface: (https) IP:9443**
    
  ⚙️ **Path to HA /config**
  ~~~bash
  /var/lib/docker/volumes/hass_config/_data
  ~~~`
  
  },
  {
    "category": "Home Assistant",

    "title": "Home Assistant Core LXC",
    "content": `<p align="center"><img src="https://avatars.githubusercontent.com/u/13844975?s=200&amp;v=4" width="100" height="100"/></p>
  
  <h1 align="center" id="heading"> Home Assistant Core LXC </h1>
  
  A standalone installation of Home Assistant Core refers to a setup where the Home Assistant Core software is installed directly on a device or operating system, without the use of Docker containers. This provides a simpler, but less flexible and scalable solution, as the software is tightly coupled with the underlying system.
  
  <h4>🛈 If the LXC is created Privileged, the script will automatically set up USB passthrough.</h4>
  <h4>🛈 Use Ubuntu 24.04 ONLY</h4>
  <h4>🛈 Requires PVE 8.2.2 with kernel 6.8.4-3-pve or newer</h4>
  
  To create a new Proxmox VE Home Assistant Core LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update, Install HACS or Filebrowser, run the command below (or type update) in the <b>LXC Console</b>. <br>
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/homeassistant-core.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 8GB Storage - 2vCPU ⚡</h3>

  **Home Assistant Interface: IP:8123**
  
  ⚙️ **Path to HA /config**
  ~~~bash
  /root/.homeassistant
  ~~~`
  
  },
  {
    "category": "Home Assistant",

    "title": "Podman Home Assistant Container LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/podman.svg" height="100"/>
  <img src="https://avatars.githubusercontent.com/u/13844975?s=200&amp;v=4" height="100"/><img/></p>
  <h1 align="center" id="heading">Podman Home Assistant Container LXC</h1>

  <h3 align="center" id="heading">NOT WORKING ON ZFS</h3>
  
  A standalone Podman container-based installation of Home Assistant Core means that the Home Assistant Core software is installed inside a container managed by Podman, separate from the host operating system. This provides a flexible and scalable solution for running the software, as the container can be easily moved between host systems or isolated from other processes for security. Podman is a popular open-source tool for managing containers that is similar to Docker, but designed for use on Linux systems without a daemon.
  
  <h4>🛈 If the LXC is created Privileged, the script will automatically set up USB passthrough.</h4>

  To create a new Proxmox VE Podman Home Assistant Container LXC, run the command below in the <b>Proxmox VE Shell</b>. <br> 
  To Update Podman, Install HACS or FileBrowser, run the command below (or type update) in the <b>LXC Console</b>.
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/podman-homeassistant.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 16GB Storage - 2vCPU ⚡</h3>
    
  **Home Assistant Interface: IP:8123**
    
  ⚙️ **Path to HA /config**
  ~~~bash
  /var/lib/containers/storage/volumes/hass_config/_data
  ~~~`

  },










/* Automation */
  {
    "category": "Automation",

    "title": "ioBroker LXC",
    "content": `<p align="center"><img src="https://github.com/ioBroker/ioBroker/blob/master/img/logos/ioBroker_Logo_256px.png?raw=true" height="100"/></p>
  
  <h1 align="center" id="heading"> ioBroker LXC </h1>
    
  <a href="https://www.iobroker.net/#en/intro" target="_blank" rel="noopener noreferrer">ioBroker</a> is an open-source platform for building and managing smart home automation systems. It provides a centralized control and management interface for connected devices, sensors, and other IoT devices. ioBroker integrates with a wide range of popular smart home systems, devices, and services, making it easy to automate tasks and processes, monitor and control devices, and collect and analyze data from a variety of sources. With its flexible architecture and easy-to-use interface, ioBroker is designed to make it simple for users to build and customize their own smart home automation systems, regardless of their technical background or experience.
  
  To create a new Proxmox VE ioBroker LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/iobroker.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 8GB Storage - 2vCPU ⚡</h3>
    
  **ioBroker Interface: IP:8081**`
  
  },
  {
    "category": "Automation",

    "title": "openHAB LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/openhab.svg" height="100"/></p>
  <h1 align="center" id="heading">openHAB LXC</h1>

  <a href="https://www.openhab.org/" target="_blank" rel="noopener noreferrer">openHAB</a> is a popular open-source home automation platform that provides a vendor and technology agnostic solution for integrating and automating various smart home devices and services. It supports a wide range of devices and protocols, making it easy to bring together different systems and devices into a unified smart home ecosystem. With its user-friendly interface and powerful automation capabilities, openHAB makes it easy to create custom automations and monitor and control your smart home devices and systems, all from a single interface.
  
  To create a new Proxmox VE openHAB LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/openhab.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 8GB Storage - 2vCPU ⚡</h3>
    
  **openHAB Interface: IP:8080**`
  
  },
  {
    "category": "Automation",

    "title": "Homebridge LXC",
    "content": `<p align="center"><img src="https://homebridge.io/assets/images/logo.svg" height="100"/></p>
  <h1 align="center" id="heading">Homebridge LXC</h1>

  <a href="https://homebridge.io/" target="_blank" rel="noopener noreferrer">Homebridge</a> is a popular open-source software platform that enables you to integrate smart home devices and services that do not natively support Apple's HomeKit protocol into the HomeKit ecosystem. This allows you to control and automate these devices using Siri, the Home app, or other HomeKit-enabled apps, making it easy to bring together a variety of different devices into a unified smart home system. With Homebridge, you can expand the capabilities of your smart home, unlocking new possibilities for automating and controlling your devices and systems.
  
  To create a new Proxmox VE Homebridge LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/homebridge.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 4GB Storage - 1vCPU ⚡</h3>
    
  **Homebridge Interface: IP:8581**
  
  ⚙️ **Initial Login**
  
  **username** <code class="highlighter-rouge">admin</code>
  
  **password** <code class="highlighter-rouge">admin</code>`
  
  },
  {
    "category": "Automation",

    "title": "ESPHome LXC",
    "content": `<p align="center"><img src="https://esphome.io/_images/logo.svg?raw=true" height="100"/></p>
  <h1 align="center" id="heading">ESPHome LXC</h1>
  
  <a href="https://esphome.io/" target="_blank" rel="noopener noreferrer">ESPHome</a> is a platform for controlling ESP8266/ESP32-based devices using configuration files and integrating them with Home Automation systems. It provides a simple and flexible way to set up and manage the functionality of these devices, including defining and automating actions, monitoring sensors, and connecting to networks and other services. ESPHome is designed to be user-friendly and easy to use, and supports a wide range of features and integrations, making it a popular choice for home automation projects and IoT applications.
  
  To create a new Proxmox VE ESPHome LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update ESPHome, run the command below (or type update) in the <b>LXC Console</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/esphome.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 4GB Storage - 2vCPU ⚡</h3>
    
  **ESPHome Interface: IP:6052**`
  
  },
  {
    "category": "Automation",

    "title": "Node-Red LXC",
    "content": `<p align="center"><img src="https://github.com/home-assistant/brands/blob/master/custom_integrations/nodered/icon.png?raw=true" height="100"/></p>
  <h1 align="center" id="heading">Node-Red LXC</h1>

  <a href="https://nodered.org/" target="_blank" rel="noopener noreferrer">Node-RED</a> is a visual programming tool that allows developers and non-developers alike to easily wire together hardware devices, APIs, and online services to create custom applications. It provides a visual interface for building workflows, making it easy to create and modify complex integrations without having to write any code. Node-RED is used in a wide range of applications, from simple automations to complex integrations, and is known for its simplicity, versatility, and ease of use.
  
  To create a new Proxmox VE Node-RED LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Node-Red or Install Themes run the command below (or type update) in the <b>LXC Console</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/node-red.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 4GB Storage - 1vCPU ⚡</h3>
    
  **Node-Red Interface: IP:1880**`
    
  },
  {
    "category": "Automation",

    "title": "n8n LXC",
    "content": `<p align="center"><img src="https://docs.n8n.io/_images/n8n-docs-icon.svg" height="70"/></p>
  <h1 align="center" id="heading">n8n LXC</h1>
    
  <a href="https://n8n.io/" target="_blank" rel="noopener noreferrer">n8n</a> is a workflow automation tool that enables users to automate various tasks and processes by connecting various data sources, systems, and services. It provides a visual interface for building workflows, allowing users to easily define and automate complex sequences of actions, such as data processing, conditional branching, and API calls. n8n supports a wide range of integrations, making it a versatile tool for automating a variety of use cases, from simple data processing workflows to complex business processes. With its extendable architecture, n8n is designed to be easily customizable and can be adapted to meet the specific needs of different users and industries.
  
  To create a new Proxmox VE n8n LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update n8n, run the command below (or type update) in the <b>LXC Console</b>.
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/n8n.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 3GB Storage - 2vCPU ⚡</h3>
    
  **n8n Interface: IP:5678**`
  
  },
  {
    "category": "Automation",

    "title": "FHEM LXC",
    "content": `<p align="center"><img src="https://avatars.githubusercontent.com/u/45183393?s=100&v=4" height="100"/></p>
  <h1 align="center" id="heading">FHEM LXC</h1>

  <a href="https://fhem.de/" target="_blank" rel="noopener noreferrer">FHEM</a> stands for "Freundliche Hausautomation und Energie-Messung," which translates to "Friendly Home Automation and Energy Measurement" in English. The software can interface with a wide range of devices, including lighting systems, thermostats, weather stations, and media devices, among others.
  
  <h4>🛈 If the LXC is created Privileged, the script will automatically set up USB passthrough.</h4>
  
  To create a new Proxmox VE FHEM LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/fhem.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 8GB Storage - 2vCPU ⚡</h3>
    
  **FHEM Interface: IP:8083**`
  
  },










/* MQTT */
  {
   "category": "MQTT",

   "title": "MQTT LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/eclipsemosquitto.svg" height="100"/></p>
  <h1 align="center" id="heading">MQTT LXC</h1>
  
  <a href="https://mosquitto.org/" target="_blank" rel="noopener noreferrer">Eclipse Mosquitto</a> is an open-source message broker that implements the MQTT (Message Queuing Telemetry Transport) protocol. It is a lightweight and simple-to-use message broker that allows IoT devices and applications to communicate with each other by exchanging messages in real-time. Mosquitto is widely used in IoT applications, due to its low resource requirements and its compatibility with a wide range of devices and platforms
  
  To create a new Proxmox VE MQTT LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/mqtt.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  512MiB RAM - 2GB Storage - 1vCPU ⚡</h3>
    
  [Post Install](https://github.com/tteck/Proxmox/discussions/2921)`
  
  },
  {
    "category": "MQTT",

    "title": "EMQX LXC",
    "content": `<p align="center"><img src="https://github.com/hassio-addons/repository/blob/master/emqx/icon.png?raw=true" height="100"/></p>
  <h1 align="center" id="heading">EMQX LXC</h1>

  <a href="https://www.emqx.io/" target="_blank" rel="noopener noreferrer">EMQX</a> is an open-source MQTT broker that features a high-performance, real-time message processing engine. It is designed to handle large-scale IoT deployments, providing fast and reliable message delivery for connected devices. EMQX is known for its scalability, reliability, and low latency, making it a popular choice for IoT and M2M applications. It also offers a wide range of features and plugins for enhanced security, monitoring, and management.
  
  To create a new Proxmox VE EMQX LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
    bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/emqx.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 4GB Storage - 2vCPU ⚡</h3>
  
  
  **EMQX Interface: IP:18083** 
    
  ⚙️ **Initial Login**
  
  **username** <code class="highlighter-rouge">admin</code>

  **password** <code class="highlighter-rouge">public</code>

  ⚙️ **Setup**
  
  Access Control ➡ Authentication  ➡ Create ➡ Next ➡ Next ➡ Create ➡ Users ➡ Add ➡ Username / Password (to authenicate with MQTT) ➡ Save. You're now ready to enjoy a high-performance MQTT Broker.`
  
  },
  {
    "category": "MQTT",

    "title": "HiveMQ CE LXC",
    "content": `<p align="center"><img src="https://www.hivemq.com/_app/immutable/assets/tw-hmq-logo.buita3kF.svg?raw=true" height="100"/></p>
  <h1 align="center" id="heading">HiveMQ CE LXC</h1>

  <a href="https://www.hivemq.com/" target="_blank" rel="noopener noreferrer">HiveMQ CE</a> is a Java-based open source MQTT broker that fully supports MQTT 3.x and MQTT 5.
  
  To create a new Proxmox VE HiveMQ CE LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/hivemq.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 4GB Storage - 1vCPU ⚡</h3>
  
  
  To check if HiveMQ is listening to the default port for MQTT
  ~~~bash
  lsof -i :1883
  ~~~`
  },
  {
    "category": "MQTT",

    "title": "RabbitMQ LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/rabbitmq/rabbitmq-website/main/static/img/rabbitmq-logo.svg" height="100"/></p>
  <h1 align="center" id="heading">RabbitMQ LXC</h1>

  <a href="https://www.rabbitmq.com/" target="_blank" rel="noopener noreferrer">RabbitMQ</a> is a reliable and mature messaging and streaming broker, which is easy to deploy on cloud environments, on-premises, and on your local machine.
  
  To create a new Proxmox VE RabbitMQ LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update RabbitMQ, run the command below (or type update) in the <b>LXC Console</b>.
  
  ~~~bash
    bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/rabbitmq.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 4GB Storage - 1vCPU ⚡</h3>
  
  
  **RabbitMQ Interface: IP:15672** 
    
  ⚙️ **Initial Login**
  
  **username** <code class="highlighter-rouge">proxmox</code>

  **password** <code class="highlighter-rouge">proxmox</code>`
  
  },









/* Database */
  {
    "category": "Database",

    "title": "Mariadb LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/mariadb.svg" height="100"/></p>
  <h1 align="center" id="heading">Mariadb LXC</h1>
  
  <a href="https://mariadb.org/" target="_blank" rel="noopener noreferrer">MariaDB</a> is a fork of the popular MySQL database management system that is developed and maintained by the open-source community. It is also commercially supported, offering enterprise-level features and support for organizations that require them. MariaDB aims to maintain high compatibility with MySQL, ensuring a drop-in replacement capability.
  
  To create a new Proxmox VE Mariadb LXC, run the command below in the <b>Proxmox VE Shell</b>.
    
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/mariadb.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 4GB Storage - 1vCPU ⚡</h3>
  
  [Post Install](https://github.com/tteck/Proxmox/discussions/2915)`
  },
  {
    "category": "Database",

    "title": "PostgreSQL LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/postgresql.svg" height="100"/></p>
  <h1 align="center" id="heading">PostgreSQL LXC</h1>
  <h3 align="center"> Option to Install Adminer</h3>
  
  <a href="https://www.postgresql.org/" target="_blank" rel="noopener noreferrer">PostgreSQL</a> (often referred to as Postgres) is an open-source relational database management system that is known for its extensibility and strict adherence to SQL standards. It is a free and powerful database solution, suitable for a wide range of applications, from small projects to large enterprise systems. PostgreSQL is widely used for its reliability, feature-richness, and robustness.
  
  To create a new Proxmox VE PostgreSQL LXC, run the command below in the <b>Proxmox VE Shell</b>.
    
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/postgresql.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 4GB Storage - 1vCPU ⚡</h3>

  ⚙️ <a href="https://www.adminer.org/" target="_blank" rel="noopener noreferrer">**Adminer**</a> is a full-featured database management tool
    
  **Adminer Interface: IP/adminer/**
  
  [Post Install](https://github.com/tteck/Proxmox/discussions/2916)`
  },
  {
    "category": "Database",

    "title": "InfluxDB LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/influx.svg" height="100"/></p>
  <h1 align="center" id="heading">InfluxDB LXC</h1>
  <h3 align="center"> Options to Install InfluxDB v1 or v2 and Telegraf</h3>
  * Choosing InfluxDB v1 will result in Chronograf being installed automatically.
  
  <a href="https://www.influxdata.com/" target="_blank" rel="noopener noreferrer">InfluxDB</a> is designed to handle high write and query loads, and is optimized for storing and analyzing time-stamped data, such as metrics, events, and logs. InfluxDB supports SQL-like query language and has a built-in HTTP API for data ingestion and retrieval. It's commonly used for IoT and industrial applications where time-series data is involved.
  
  <a href="https://www.influxdata.com/time-series-platform/telegraf/" target="_blank" rel="noopener noreferrer">Telegraf</a> is a server agent that collects, processes, and aggregates metrics and events data from different sources, such as systems, databases, and APIs, and outputs the data to various outputs, such as InfluxDB, Prometheus, Elasticsearch, and many others.
  
  To create a new Proxmox VE InfluxDB LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
    bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/influxdb.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 8GB Storage - 2vCPU ⚡</h3>

 InfluxDB V1 (Chronograf) Interface: IP:8888

 InfluxDB V2 Interface: IP:8086

 ⚙️ **InfluxDB Configuration**
  
  ~~~yaml
  nano /etc/influxdb/influxdb.conf
  ~~~
  
 ⚙️ **Telegraf Configuration**
  
  ~~~yaml
  nano /etc/telegraf/telegraf.conf
  ~~~`
  
  },
  {
    "category": "Database",

    "title": "Apache-CouchDB LXC",
    "content": `<p align="center"><img src="https://couchdb.apache.org/image/couch@2x.png" height="100"/></p>
  <h1 align="center" id="heading">Apache-CouchDB LXC</h1>
  
  <a href="https://couchdb.apache.org/" target="_blank" rel="noopener noreferrer">Apache-CouchDB</a> Seamless multi-master sync, that scales from Big Data to Mobile, with an Intuitive HTTP/JSON API and designed for Reliability.
  
  To create a new Proxmox VE Apache-CouchDB LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
    bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/apache-couchdb.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  4GB RAM - 10GB Storage - 2vCPU ⚡</h3>

  To Show Login Credentials

  ~~~bash
  cat CouchDB.creds
  ~~~

  **Apache-CouchDB Interface: IP:5984/_utils/**`
  
  },
  {
    "category": "Database",

    "title": "Apache-Cassandra LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/apachecassandra.svg" height="100"/></p>
  <h1 align="center" id="heading">Apache-Cassandra LXC</h1>
  
  <a href="https://cassandra.apache.org/" target="_blank" rel="noopener noreferrer">Apache-Cassandra</a> is an open source NoSQL distributed database trusted by thousands of companies for scalability and high availability without compromising performance.
  
  To create a new Proxmox VE Apache-Cassandra LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
    bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/apache-cassandra.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 4GB Storage - 1vCPU ⚡</h3>

 ⚙️ **Apache-Cassandra Configuration**
  
  ~~~yaml
  nano /etc/cassandra/cassandra.yaml
  ~~~`
  
  },
  {
    "category": "Database",

    "title": "Redis LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/redis.svg" height="100"/></p>
  <h1 align="center" id="heading">Redis LXC</h1>
  
  <a href="https://redis.io/" target="_blank" rel="noopener noreferrer">Redis</a> is an open-source, in-memory data store used by millions of developers as a cache, vector database, document database, streaming engine, and message broker.
  
  To create a new Proxmox VE Redis LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
    bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/redis.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 4GB Storage - 1vCPU ⚡</h3>

 ⚙️ **Redis Configuration**
  
  ~~~yaml
  nano /etc/redis/redis.conf
  ~~~`
  
  },
  {
    "category": "Database",

    "title": "MongoDB LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/mongodb-spring-green.svg" height="100"/></p>
  <h1 align="center" id="heading">MongoDB LXC</h1>
  
  <a href="https://www.mongodb.com/" target="_blank" rel="noopener noreferrer">MongoDB</a> is a NoSQL database that uses a document-oriented data model, storing data in JSON-like documents with dynamic schemas. This design offers flexibility and scalability, making it ideal for handling large volumes of data. MongoDB supports indexing, replication, and load balancing, ensuring high performance and availability, and can distribute data across multiple servers, making it well-suited for big data applications.
  
  To create a new Proxmox VE MongoDB LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
    bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/mongodb.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  512MiB RAM - 4GB Storage - 1vCPU ⚡</h3`
  
  },
  {
    "category": "Database",

    "title": "Pocketbase LXC",
    "content": `<p align="center"><img src="https://pocketbase.io/images/logo.svg" height="100"/></p>
  <h1 align="center" id="heading">Pocketbase LXC</h1>
  
  <a href="https://pocketbase.io/" target="_blank" rel="noopener noreferrer">Pocketbase</a> is an open source backend consisting of embedded database (SQLite) with realtime subscriptions, built-in auth management, convenient dashboard UI and simple REST-ish API.
  
  To create a new Proxmox VE Pocketbase LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
    bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/pocketbase.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  512MiB RAM - 8GB Storage - 1vCPU ⚡</h3>

 **Pocketbase Interface: IP:8080/_/**`
  
  },










/* Zigbee - Zwave - Matter */
  {
    "category": "Zigbee - Zwave - Matter",

    "title": "Zigbee2MQTT LXC",
    "content": `<p align="center"><img src="https://github.com/Koenkk/zigbee2mqtt/blob/master/images/logo_bee_only.png?raw=true" height="100"/></p>
  <h1 align="center" id="heading">Zigbee2MQTT LXC</h1>

  <h3 align="center"> Option to switch to Edge/dev branch</h3>
  
  <a href="https://www.zigbee2mqtt.io/" target="_blank" rel="noopener noreferrer">Zigbee2MQTT</a> is an open-source software project that allows you to use Zigbee-based smart home devices (such as those sold under the Philips Hue and Ikea Tradfri brands) with MQTT-based home automation systems, like Home Assistant, Node-RED, and others. The software acts as a bridge between your Zigbee devices and MQTT, allowing you to control and monitor these devices from your home automation system.
  
  <h4>🛈 If the LXC is created Privileged, the script will automatically set up USB passthrough.</h4>

  To create a new Proxmox VE Zigbee2MQTT LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Zigbee2MQTT, run the command below (or type update) in the <b>LXC Console</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/zigbee2mqtt.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 4GB Storage - 2vCPU - Privileged ⚡</h3>
 
  As an alternative option, you can use Alpine Linux and the Zigbee2MQTT package to create a Zigbee2MQTT LXC container with faster creation time and minimal system resource usage. <br>

  To create a new Proxmox VE Alpine-Zigbee2MQTT LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  ~~~bash
  bash -c "$(wget -qO - https://github.com/tteck/Proxmox/raw/main/ct/alpine-zigbee2mqtt.sh)"
  ~~~

  <h3 align="center" id="heading">⚡ Default Settings:  256MiB RAM - 300MiB Storage - 1vCPU - Privileged ⚡</h3>

  [Post Install](https://github.com/tteck/Proxmox/discussions/2917)`
  },
  {
    "category": "Zigbee - Zwave - Matter",

    "title": "deCONZ LXC",
    "content": `<p align="center"><img src="https://phoscon.de/img/phoscon-logo128x.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> deCONZ LXC </h1>
  
  <a href="https://www.phoscon.de/en/conbee2/software#deconz" target="_blank" rel="noopener noreferrer">deCONZ</a> is a software for managing and controlling Zigbee-based smart home devices. It allows for setting up, configuring and visualizing the status of connected devices, as well as for triggering actions and automations. It works as a bridge between the Zigbee network and other home automation systems and can be used as a standalone solution or integrated into existing setups.
  
  <h4>🛈 If the LXC is created Privileged, the script will automatically set up USB passthrough.</h4>

  To create a new Proxmox VE deCONZ LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
    bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/deconz.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 4GB Storage - 2vCPU - Privileged ⚡</h3>
  
  **deCONZ Interface: IP:80**`
  
  },
  {
    "category": "Zigbee - Zwave - Matter",

    "title": "Z-Wave JS UI LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/zwave.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> Z-Wave JS UI LXC </h1>
  
  <a href="https://github.com/zwave-js/zwave-js-ui#" target="_blank" rel="noopener noreferrer">Z-Wave JS UI</a> is an open-source software that serves as a gateway between Z-Wave devices and MQTT (Message Queuing Telemetry Transport) protocol, allowing users to control and monitor their Z-Wave devices via a user interface. The software provides a configurable platform to manage Z-Wave networks and integrate with other smart home systems through MQTT.
  
  <h4>🛈 If the LXC is created Privileged, the script will automatically set up USB passthrough.</h4>
  
  To create a new Proxmox VE Z-Wave JS UI LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Z-Wave JS UI, run the command below (or type update) in the <b>LXC Console</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/zwave-js-ui.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 4GB Storage - 2vCPU - Privileged ⚡</h3>
  
  **Z-Wave JS UI Interface: IP:8091**`
  
  },
  {
    "category": "Zigbee - Zwave - Matter",

    "title": "Matterbridge LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/Luligu/matterbridge/main/frontend/public/matterbridge%20624x624.png" height="100"/></p>
  
  <h1 align="center" id="heading"> Matterbridge LXC </h1>
  
  <a href="https://github.com/Luligu/matterbridge" target="_blank" rel="noopener noreferrer">Matterbridge</a> allows you to have all your Matter devices up and running in a couple of minutes without having to deal with the pairing process of each single device.
  
  <h4>🛈 If the LXC is created Privileged, the script will automatically set up USB passthrough.</h4>
  
  To create a new Proxmox VE Matterbridge LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Matterbridge, run the command below (or type update) in the <b>LXC Console</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/matterbridge.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 4GB Storage - 1vCPU ⚡</h3>
  
  **Matterbridge Interface: IP:8283**`
  
  },









/* Monitoring - Analytics */
  {
    "category": "Monitoring - Analytics",

    "title": "Uptime Kuma LXC",
    "content": `<p align="center"><img src="https://github.com/louislam/uptime-kuma/blob/master/public/icon.png?raw=true" height="100"/></p>
  
  <h1 align="center" id="heading"> Uptime Kuma LXC </h1>
  
  <a href="https://github.com/louislam/uptime-kuma#uptime-kuma" target="_blank" rel="noopener noreferrer">Uptime Kuma</a> is a monitoring and alerting system that tracks the availability and performance of servers, websites, and other internet-connected devices. It can be self-hosted and is open-source, offering a visually appealing interface for monitoring and receiving notifications about downtime events.
  
  To create a new Proxmox VE Uptime Kuma LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Uptime Kuma, run the command below (or type update) in the <b>LXC Console</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/uptimekuma.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 2GB Storage - 1vCPU ⚡</h3>
  
  **Uptime Kuma Interface: IP:3001**`
  
  },
  {
    "category": "Monitoring - Analytics",

    "title": "Zabbix LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/zabbix.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> Zabbix LXC </h1>
  
  <a href="https://www.zabbix.com/" target="_blank" rel="noopener noreferrer">Zabbix</a> is an all-in-one monitoring solution with a variety of enterprise-grade features available right out of the box.
  
  To create a new Proxmox VE Zabbix LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/zabbix.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  4GB RAM - 6GB Storage - 2vCPU ⚡</h3>
  
  username: <code class="highlighter-rouge">Admin</code> password: <code class="highlighter-rouge">zabbix</code>

  Database credentials: <code class="highlighter-rouge">cat zabbix.creds</code>

  **Zabbix Interface: IP:5454**`
  
  },
  {
    "category": "Monitoring - Analytics",

    "title": "Pi.Alert LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/leiweibau/Pi.Alert/main/front/img/favicons/glass_black_white.png" height="100"/></p>
  
  <h1 align="center" id="heading"> Pi.Alert LXC </h1>
  
  <a href="https://github.com/leiweibau/Pi.Alert/" target="_blank" rel="noopener noreferrer">Pi.Alert</a> is a WIFI / LAN intruder detector. Checks the devices connected and alert you with unknown devices. It also warns of the disconnection of "always connected" devices.
  
  To create a new Proxmox VE Pi.Alert LXC, run the command below in the <b>Proxmox VE Shell</b>.<br>
  To Update Pi.Alert, run the command below (or type update) in the <b>LXC Console</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/pialert.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  512MiB RAM - 3GB Storage - 1vCPU ⚡</h3>
  
  **Pi.Alert Interface: IP/pialert/**`
  
  },
  {
    "category": "Monitoring - Analytics",

    "title": "Umami LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/umami.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> Umami LXC </h1>
  
  <a href="https://umami.is/" target="_blank" rel="noopener noreferrer">Umami</a> makes it easy to collect, analyze, and understand your web data — while maintaining visitor privacy and data ownership.
  
  To create a new Proxmox VE Umami LXC, run the command below in the <b>Proxmox VE Shell</b>.<br>
  To Update Umami, run the command below (or type update) in the <b>LXC Console</b>.

  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/umami.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 12GB Storage - 1vCPU ⚡</h3>
  
  **Umami Interface: IP:3000**`
  
  },
  {
    "category": "Monitoring - Analytics",

    "title": "Glances",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/nicolargo/glances/develop/docs/_static/Glances%20Logo.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> Glances </h1>
  
  <a href="https://nicolargo.github.io/glances/" target="_blank" rel="noopener noreferrer">Glances</a> is an open-source system cross-platform monitoring tool. It allows real-time monitoring of various aspects of your system such as CPU, memory, disk, network usage etc.
  
  To Install Glances, ⚠️ run the command below in the <b>LXC Console</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/misc/glances.sh)"
  ~~~
  
  **Glances Interface: IP:61208**`
  
  },
  {
    "category": "Monitoring - Analytics",

    "title": "WatchYourLAN LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/aceberg/WatchYourLAN/main/assets/logo.png" height="100"/></p>
  
  <h1 align="center" id="heading"> WatchYourLAN LXC </h1>
  
  <a href="https://github.com/aceberg/WatchYourLAN" target="_blank" rel="noopener noreferrer">WatchYourLAN</a> is a lightweight network IP scanner with web GUI.
  
  To create a new Proxmox VE WatchYourLAN LXC, run the command below in the <b>Proxmox VE Shell</b>.<br>
  To Update WatchYourLAN, run the command below (or type update) in the <b>LXC Console</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/watchyourlan.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  512MiB RAM - 2GB Storage - 1vCPU ⚡</h3>
  
  **WatchYourLAN Interface: IP:8840**`
  
  },
  {
    "category": "Monitoring - Analytics",

    "title": "SmokePing LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/dperson/smokeping/master/logo.png" height="100"/></p>
  
  <h1 align="center" id="heading"> SmokePing LXC </h1>
  
  <a href="https://oss.oetiker.ch/smokeping/" target="_blank" rel="noopener noreferrer">SmokePing</a> is a deluxe latency measurement tool. It can measure, store and display latency, latency distribution and packet loss.
  
  To create a new Proxmox VE SmokePing LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/smokeping.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  512MiB RAM - 2GB Storage - 1vCPU ⚡</h3>
  
  **SmokePing Interface: IP/smokeping**`
  
  },
  {
    "category": "Monitoring - Analytics",

    "title": "Notifiarr LXC",
    "content": `<p align="center"><img src="https://notifiarr.com/images/logo/notifiarr.png?raw=true" height="100"/></p>
  
  <h1 align="center" id="heading"> Notifiarr LXC </h1>
  
  <a href="https://notifiarr.com/" target="_blank" rel="noopener noreferrer">Notifiarr</a> is a purpose built system to bring many applications together to manage and customize notifications via Discord. You can monitor many aspects of your network(s), be notified of downtime, be notified of health issues, etc
  
  To create a new Proxmox VE Notifiarr LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Notifiarr, run the command below (or type update) in the <b>LXC Console</b>.
  
  ~~~bash
  bash -c "$(wget -qO - https://github.com/tteck/Proxmox/raw/main/ct/notifiarr.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  512MiB RAM - 2GB Storage - 1vCPU ⚡</h3>
  
  Manually edit <code class="highlighter-rouge">/etc/notifiarr/notifiarr.conf</code> to enter the API key from Notifiarr.com, and create a password for the UI.

  **Notifiarr Interface: IP:5454**`
  
  },
  {
    "category": "Monitoring - Analytics",

    "title": "OpenObserve LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/tteck/Proxmox/main/misc/images/openobsecure.png" height="100"/></p>
  
  <h1 align="center" id="heading"> OpenObserve LXC </h1>
  
  <a href="https://openobserve.ai/" target="_blank" rel="noopener noreferrer">OpenObserve</a> is a simple yet sophisticated log search, infrastructure monitoring, and APM solution.
  
  To create a new Proxmox VE OpenObserve LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update OpenObserve, run the command below (or type update) in the <b>LXC Console</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/openobserve.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  512MiB RAM - 3GB Storage - 1vCPU ⚡</h3>
  
  **Credentials:** <code class="highlighter-rouge">cat /opt/openobserve/data/.env</code>

  **OpenObserve Interface: IP:5080**`
  
  },
  {
    "category": "Monitoring - Analytics",
  
    "title": "Change Detection LXC",
    "content": `<p align="center"><img src="https://github.com/dgtlmoon/changedetection.io/blob/master/changedetectionio/static/images/avatar-256x256.png?raw=true" height="100"/></p>
  
  <h1 align="center" id="heading"> Change Detection LXC </h1>
  
  <a href="https://changedetection.io/" target="_blank" rel="noopener noreferrer">Change Detection</a> is a service that allows you to monitor changes to web pages and receive notifications when changes occur. It can be used for a variety of purposes such as keeping track of online price changes, monitoring news websites for updates, or tracking changes to online forums.
  
  To create a new Proxmox VE Change Detection LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Change Detection, run the command below (or type update) in the <b>LXC Console</b>.
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/changedetection.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  512MiB RAM - 2GB Storage - 1vCPU ⚡</h3>
  
  **Change Detection Interface: IP:5000**`
  
  
  },
  {
    "category": "Monitoring - Analytics",

    "title": "Prometheus LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/prometheus.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> Prometheus LXC </h1>
  
  <a href="https://prometheus.io/" target="_blank" rel="noopener noreferrer">Prometheus</a> is widely used to monitor the performance and health of various infrastructure components and applications, and trigger alerts based on predefined rules. It has a multi-dimensional data model and supports various data sources and exporters, making it an extremely flexible and scalable monitoring solution.
  
  To create a new Proxmox VE Prometheus LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/prometheus.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 4GB Storage - 1vCPU ⚡</h3>
  
  **Prometheus Interface: IP:9090**`
  
  },
  {
    "category": "Monitoring - Analytics",

    "title": "PeaNUT LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/Brandawg93/PeaNUT/main/src/app/icon.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> PeaNUT LXC </h1>
  
  <a href="https://github.com/Brandawg93/PeaNUT/" target="_blank" rel="noopener noreferrer">PeaNUT</a> is a Tiny Dashboard for Network UPS Tools.<br>

  <h4>🛈 configure the files in <code class="highlighter-rouge">/etc/nut</code> then update the PeaNUT service environment variables in <code class="highlighter-rouge">/etc/systemd/system/peanut.service</code>.</h4>

  To create a new Proxmox VE PeaNUT LXC, run the command below in the <b>Proxmox VE Shell</b>.<br>
  To Update PeaNUT, run the command below (or type update) in the <b>LXC Console</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/peanut.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 4GB Storage - 1vCPU ⚡</h3>
  
  **PeaNUT Interface: IP:3000**`
  
  },
  {
    "category": "Monitoring - Analytics",

    "title": "MySpeed LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/gnmyt/myspeed/development/web/public/assets/img/logo.png" height="100"/></p>
  
  <h1 align="center" id="heading"> MySpeed LXC </h1>
  
  <a href="https://myspeed.dev/" target="_blank" rel="noopener noreferrer">MySpeed</a> is a speed test analysis software that records your internet speed for up to 30 days.
  
  To create a new Proxmox VE MySpeed LXC, run the command below in the <b>Proxmox VE Shell</b>.<br>
  To Update MySpeed, run the command below (or type update) in the <b>LXC Console</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/myspeed.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 4GB Storage - 1vCPU ⚡</h3>
  
  **MySpeed Interface: IP:5216**`
  
  },
  {
    "category": "Monitoring - Analytics",

    "title": "Grafana LXC",
    "content": `<p align="center"><img src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fdocs.checkmk.com%2Flatest%2Fimages%2Fgrafana_logo.png&f=1&nofb=1" height="100"/></p>
  
  <h1 align="center" id="heading"> Grafana LXC </h1>
  
  <a href="https://grafana.com/" target="_blank" rel="noopener noreferrer">Grafana</a> is a data visualization and monitoring platform that enables users to query, visualize, alert on and understand metrics, logs, and other data sources. It integrates with various data sources, including Prometheus, InfluxDB, Elasticsearch, and many others, to present a unified view of the data and enable users to create insightful and interactive dashboards.
  
  To create a new Proxmox VE Grafana LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/grafana.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  512MiB RAM - 2GB Storage - 1vCPU ⚡</h3>
 
  As an alternative option, you can use Alpine Linux and the Grafana package to create a Grafana LXC container with faster creation time and minimal system resource usage. <br>
  
  To create a new Proxmox VE Alpine-Grafana LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  ~~~bash
  bash -c "$(wget -qO - https://github.com/tteck/Proxmox/raw/main/ct/alpine-grafana.sh)"
  ~~~

  <h3 align="center" id="heading">⚡ Default Settings:  256Mib RAM - 500MiB Storage - 1vCPU ⚡</h3>

  **Grafana Interface: IP:3000**
  
  ⚙️ **Initial Login**
  
  **username** <code class="highlighter-rouge">admin</code>
  
  **password** <code class="highlighter-rouge">admin</code>`
  
  },










/* Docker - Kubernetes */
  {
    "category": "Docker - Kubernetes",

    "title": "Docker LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/docker.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> Docker LXC </h1>
  <h3 align="center"> Options to Install Portainer and/or Docker Compose V2 </h3>
  
  <a href="https://www.docker.com/" target="_blank" rel="noopener noreferrer">Docker</a> is an open-source project for automating the deployment of applications as portable, self-sufficient containers.
  
  <h4>🛈 If the LXC is created Privileged, the script will automatically set up USB passthrough.</h4>

  To create a new Proxmox VE Docker LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/docker.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 4GB Storage - 2vCPU ⚡</h3>
  
  As an alternative option, you can use Alpine Linux and the Docker package to create a Docker LXC container with faster creation time and minimal system resource usage. <br>
  
  To create a new Proxmox VE Alpine-Docker LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  ~~~bash
  bash -c "$(wget -qO - https://github.com/tteck/Proxmox/raw/main/ct/alpine-docker.sh)"
  ~~~

  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 2GB Storage - 1vCPU ⚡</h3>

  <h4>🛈 Run Compose V2 by replacing the hyphen (-) with a space, using docker compose, instead of docker-compose.</h4>
  
  **Portainer Interface: (https) IP:9443**`

  },
  {
    "category": "Docker - Kubernetes",

    "title": "Dockge LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/louislam/dockge/master/frontend/public/icon.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> Dockge LXC </h1>
  <h3 align="center"> Options to add Immich and/or Home Assistant </h3>
  
  <a href="https://github.com/louislam/dockge" target="_blank" rel="noopener noreferrer">Dockge</a> is a fancy, easy-to-use and reactive self-hosted docker compose.yaml stack-oriented manager.
  
  <h4>🛈 If the LXC is created Privileged, the script will automatically set up USB passthrough.</h4>

  To create a new Proxmox VE Dockge LXC, run the command below in the <b>Proxmox VE Shell</b>.

  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/dockge.sh)"
  ~~~

  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 18GB Storage - 2vCPU ⚡</h3>
  
  **Dockge Interface: IP:5001**

  To update Dockge
  ~~~bash
  cd /opt/dockge
  docker compose pull
  docker compose up -d
  ~~~`
  },
  /*{
    "category": "Docker - Kubernetes",

    "title": "Umbrel LXC",
  
    "content": `<p align="center"><img src="https://uploads-ssl.webflow.com/62966b49981ba146f4842f45/62966b49981ba15b44842fe4_umbrel-logo-compact-purple.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> Umbrel LXC </h1>
  
  <a href="https://umbrel.com/" target="_blank" rel="noopener noreferrer">Umbrel</a> is an open-source project that provides a secure and easy-to-use software solution for hosting your own personal server, enabling you to have full control over your data and online services. The OS provides a comprehensive web interface and pre-configured applications for various tasks, making self-hosting accessible for non-technical users.
  
  🛈 *If the LXC is created Privileged, the script will automatically set up USB passthrough.*

  To create a new Proxmox VE Umbrel LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/umbrel.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 8GB Storage - 2vCPU ⚡</h3>
  
  **Umbrel Interface: IP** (a reboot is required before app installs)`
  
  },*/
  {
    "category": "Docker - Kubernetes",

    "title": "CasaOS LXC",
  
    "content": `<p align="center"><img src="https://www.casaos.io/images/casa_9c491a0f.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> CasaOS LXC </h1>
  
  <a href="https://www.casaos.io/" target="_blank" rel="noopener noreferrer">CasaOS</a> is a software that aims to make it easy for users to create a personal cloud system at home. It uses the Docker ecosystem to provide a simple, user-friendly experience for managing various applications and services.
  
  <h4>🛈 If the LXC is created Privileged, the script will automatically set up USB passthrough.</h4>

  To create a new Proxmox VE CasaOS LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/casaos.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 8GB Storage - 2vCPU ⚡</h3>
  
  **CasaOS Interface: IP**`
  
  },
  {
    "category": "Docker - Kubernetes",

    "title": "Runtipi LXC",
  
    "content": `<p align="center"><img src="https://runtipi.io/_next/static/media/tipi.c0b9b68e.png" height="100"/></p>
  
  <h1 align="center" id="heading"> Runtipi LXC </h1>
  
  <a href="https://runtipi.io/" target="_blank" rel="noopener noreferrer">Runtipi</a> lets you install all your favorite self-hosted apps without the hassle of configuring and managing each service. One-click installs and updates for more than 180 popular apps.
  
  </h4>🛈 If the LXC is created Privileged, the script will automatically set up USB passthrough.</h4>

  To create a new Proxmox VE Runtipi LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Runtipi, run the command below (or type update) in the <b>LXC Console</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/runtipi.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 8GB Storage - 2vCPU ⚡</h3>
  
  **Runtipi Interface: IP**`
  
  },
  /*{
    "category": "Docker - Kubernetes",

    "title": "k0s Kubernetes LXC",
  
    "content": `<p align="center"><img src="https://k0sproject.io/images/k0s-logo.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> k0s Kubernetes LXC </h1>
  <h3 align="center"> Option to Install Helm Package Manager</h3>
  
  [k0s](https://k0sproject.io/) is a lightweight, easy-to-deploy, and certified Kubernetes distribution designed to simplify the process of deploying and managing a Kubernetes cluster. It focuses on simplicity and ease-of-use while providing a secure and production-ready environment for running applications.
  
  
  To create a new Proxmox VE k0s Kubernetes LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/k0s.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 4GB Storage - 2vCPU ⚡</h3>

  ⚙️ **Config Path**

  ~~~yaml
  /etc/k0s/k0s.yaml
  ~~~`
  
  },*/
  
  {
    "category": "Docker - Kubernetes",

    "title": "Podman LXC",
  
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/podman.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> Podman LXC </h1>

  <h3 align="center" id="heading">NOT WORKING ON ZFS</h3>
  
  <a href="https://podman.io/" target="_blank" rel="noopener noreferrer">Podman</a> is an open-source, daemonless, and portable container engine that allows users to manage containers on Linux systems without the need for a daemon or system service to be running in the background. It provides an API and a command-line interface that can be used to create, run, and manage containers and their associated networks, volumes, and images. It is built on top of the Open Container Initiative (OCI) runtime specification, making it compatible with other OCI-compliant container engines.
  
  To create a new Proxmox VE Podman LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/podman.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 4GB Storage - 2vCPU ⚡</h3>`
    
  },










/* Operating System */
  {
    "category": "Operating System",

    "title": "Debian LXC",
    "content": `<p align="center"><img src="https://www.debian.org/Pics/debian-logo-1024x576.png" height="100"/></p>
  
  <h1 align="center" id="heading"> Debian LXC </h1>
  Debian Linux is a distribution that emphasizes free software. It supports many hardware platforms.

  To create a new Proxmox VE Debian LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/debian.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  512MiB RAM - 2GB Storage - 1vCPU ⚡</h3>`
  
  },
  {
    "category": "Operating System",

    "title": "Debian 12 VM",
    "content": `<p align="center"><img src="https://www.debian.org/Pics/debian-logo-1024x576.png" height="100"/></p>
  
  <h1 align="center" id="heading"> Debian 12 VM </h1>
  Debian Linux is a distribution that emphasizes free software. It supports many hardware platforms.

  To create a new Proxmox VE Debian 12 VM, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/vm/debian-vm.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 2GB Storage - 2vCPU ⚡</h3>
  
  More Info at https://github.com/tteck/Proxmox/discussions/1988`
  
  },
  {
    "category": "Operating System",

    "title": "Ubuntu LXC",
    "content": `<p align="center"><img src="https://assets.ubuntu.com/v1/29985a98-ubuntu-logo32.png" height="100"/></p>
  
  <h1 align="center" id="heading"> Ubuntu LXC </h1>
  <h3 align="center" id="heading"> Option to select version </h3>
  Ubuntu is a distribution based on Debian, designed to have regular releases and a consistent user experience.
  
  To create a new Proxmox VE Ubuntu LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/ubuntu.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  512MiB RAM - 2GB Storage - 1vCPU - 22.04 ⚡</h3>`
  
  },
  {
    "category": "Operating System",

    "title": "Ubuntu 22.04 VM",
    "content": `<p align="center"><img src="https://assets.ubuntu.com/v1/29985a98-ubuntu-logo32.png" height="100"/></p>
  
  <h1 align="center" id="heading"> Ubuntu 22.04 VM </h1>
  Ubuntu is a distribution based on Debian, designed to have regular releases and a consistent user experience.

  To create a new Proxmox VE Ubuntu 22.04 VM, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/vm/ubuntu2204-vm.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 2GB Storage - 2vCPU ⚡</h3>
  
  More Info at https://github.com/tteck/Proxmox/discussions/2072`
  
  },
  {
    "category": "Operating System",

    "title": "Ubuntu 24.04 VM",
    "content": `<p align="center"><img src="https://assets.ubuntu.com/v1/29985a98-ubuntu-logo32.png" height="100"/></p>
  
  <h1 align="center" id="heading"> Ubuntu 24.04 VM </h1>
  Ubuntu is a distribution based on Debian, designed to have regular releases and a consistent user experience.

  To create a new Proxmox VE Ubuntu 24.04 VM, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/vm/ubuntu2404-vm.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 2GB Storage - 2vCPU ⚡</h3>
  
  More Info at https://github.com/tteck/Proxmox/discussions/2072`
  
  },
  {
    "category": "Operating System",

    "title": "Alpine LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/alpinelinux.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> Alpine LXC </h1>
  A security-oriented, lightweight Linux distribution based on musl and BusyBox.<br>
  By default, the root password is set to <code class="highlighter-rouge">alpine</code>. If you choose to use advanced settings, you will need to define a password, autologin is currently unavailable.

  To create a new Proxmox VE Alpine LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/alpine.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  512MiB RAM - 100MiB Storage - 1vCPU ⚡</h3>

  ⚙️ **Default Password** <code class="highlighter-rouge">alpine</code>

  ⚙️ **To Update Alpine**
  
  ~~~bash
  apk update && apk upgrade
  ~~~`
  },










/* TurnKey */
  {
    "category": "TurnKey",

    "title": "TurnKey LXC Appliances",
    "content": `<p align="center"><img src="https://blog.desdelinux.net/wp-content/uploads/2017/01/TurnKey-Linux.png " height="100"/></p>
  
  <h1 align="center" id="heading"> TurnKey LXC Appliances </h1>

  <a href="https://www.turnkeylinux.org/" target="_blank" rel="noopener noreferrer">TurnKey LXC Appliances</a> is an open-source project that provides a collection of free, ready-to-use virtual appliances and installation images for various software applications and services. These appliances are pre-configured and come with all the necessary software and settings to simplify deployment and management. The goal of TurnKey Linux is to make it easier for users to set up and run popular software applications without the need for extensive manual configuration.
  
  To create a new Proxmox VE TurnKey LXC Appliance , run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/turnkey/turnkey.sh)"
  ~~~
  
  <h3 align="center" id="heading">Resource and network settings are adjustable post LXC creation.</h3>
  
  The script creates a <code class="highlighter-rouge">*.creds</code> file in the Proxmox root directory with the password of the newly created TurnKey LXC Appliance.

  ⚙️ **Retrieve Password** <code class="highlighter-rouge">cat turnkey-*name*.creds</code>`
  
  },
  {
    "category": "TurnKey",
  
    "title": "All Templates LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/proxmox.svg" height="100"/></p>
  
   <h1 align="center" id="heading"> All Templates LXC </h1>
  
   A script designed to allow for the creation of one of the many free LXC templates. Great for creating system LXCs.<br>
   The script creates a *.creds file in the Proxmox root directory with the password of the newly created LXC.<br>
   Please take note that if you plan to use this script for creating TurnKey LXCs, you'll need to modify the hostname after creation.
  
   To create a new Proxmox VE Template LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
   ~~~bash
   bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/misc/all-templates.sh)"
   ~~~
  
   <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 8GB Storage - 2vCPU- onboot 0 - DHCP - Unprivileged ⚡</h3><br>
   Resource and network settings are adjustable post LXC creation.`
  
  },










/* Server - Networking */
  {
    "category": "Server - Networking",

    "title": "Cloudflared LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/cloudflare.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> Cloudflared LXC </h1>
   
  <a href="https://www.cloudflare.com/" target="_blank" rel="noopener noreferrer">Cloudflared</a> is a command-line tool that allows you to securely access resources on the Cloudflare network, such as websites and APIs, from your local computer. It works by creating a secure tunnel between your computer and the Cloudflare network, allowing you to access resources as if they were on your local network. 
  
  To create a new Proxmox VE Cloudflared LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/cloudflared.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  512MiB RAM - 2GB Storage - 1vCPU ⚡</h3>`
  
  },
  {
    "category": "Server - Networking",

    "title": "Proxmox Backup Server LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/home-assistant/brands/master/core_integrations/proxmoxve/icon.png" height="100"/></p>
  
  <h1 align="center" id="heading"> Proxmox Backup Server LXC </h1>
   
  <a href="https://www.proxmox.com/en/proxmox-backup-server/overview" target="_blank" rel="noopener noreferrer">Proxmox Backup Server</a> is an enterprise backup solution, for backing up and restoring VMs, containers, and physical hosts. By supporting incremental, fully deduplicated backups, Proxmox Backup Server significantly reduces network load and saves valuable storage space.. 
  
  To create a new Proxmox VE Proxmox Backup Server LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/pbs.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 10GB Storage - 2vCPU ⚡</h3>

  **Proxmox Backup Server Interface: IP:8007**
  
  Set a root password if using autologin. This will be the PBS password.
  ~~~bash
  sudo passwd root
  ~~~`
  
  },
  {
    "category": "Server - Networking",

    "title": "Apt-Cacher-NG LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/linux.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> Apt-Cacher-NG LXC </h1>
   
  <a href="https://www.unix-ag.uni-kl.de/~bloch/acng/" target="_blank" rel="noopener noreferrer">Apt-Cacher-NG</a> is a caching proxy. Specialized for package files from Linux distributors, primarily for Debian (and Debian based) distributions.
  
  To create a new Proxmox VE Apt-Cacher-NG LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/apt-cacher-ng.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  512MiB RAM - 2GB Storage - 1vCPU ⚡</h3>
  
  **Apt-Cacher-NG Interface: IP:3142/acng-report.html**`
  
  },
  {
    "category": "Server - Networking",

    "title": "Zoraxy LXC",
    "content": `<p align="center"><img src="https://zoraxy.arozos.com/img/icon.png" height="100"/></p>
  
  <h1 align="center" id="heading"> Zoraxy LXC </h1>
   
  <a href="https://zoraxy.arozos.com/" target="_blank" rel="noopener noreferrer">Zoraxy</a> is an all in one homelab network routing solution. 
  
  To create a new Proxmox VE Zoraxy LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/zoraxy.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 4GB Storage - 2vCPU ⚡</h3>

  **Zoraxy Interface: IP:8000**`
  
  },
  {
    "category": "Server - Networking",

    "title": "Nginx Proxy Manager LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/nginxproxymanager.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> Nginx Proxy Manager LXC </h1>
  
  <a href="https://nginxproxymanager.com/" target="_blank" rel="noopener noreferrer">Nginx Proxy Manager</a> is a tool that provides a web-based interface to manage Nginx reverse proxies. It enables users to easily and securely expose their services to the internet by providing features such as HTTPS encryption, domain mapping, and access control. It eliminates the need for manual configuration of Nginx reverse proxies, making it easy for users to quickly and securely expose their services to the public.
  
  To create a new Proxmox VE Nginx Proxy Manager LXC Container, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Nginx Proxy Manager, run the command below (or type update) in the <b>LXC Console</b>.
  ~~~yaml
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/nginxproxymanager.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 4GB Storage - 1vCPU ⚡</h3>

  🚨 Since there are hundreds of Certbot instances, it's necessary to install the specific Certbot of your preference.
  
  Forward port <code class="highlighter-rouge">80</code> and <code class="highlighter-rouge">443</code> from your router to your Nginx Proxy Manager LXC IP.
  
  Add the command below to your <code class="highlighter-rouge">configuration.yaml</code> in Home Assistant.
  ~~~yaml
  http:
    use_x_forwarded_for: true
    trusted_proxies:
      - 192.168.100.27 ###(Nginx Proxy Manager LXC IP)###
  ~~~
  
  **Nginx Proxy Manager Interface: IP:81**
  
  ⚙️ **Initial Login**
  
  **username** <code class="highlighter-rouge">admin@example.com</code>
  
  **password** <code class="highlighter-rouge">changeme</code>`

  },
  {
    "category": "Server - Networking",

    "title": "YunoHost LXC",
    "content": `<p align="center"><img src="https://yunohost.org/_images/ynh_logo_white_300dpi.png" height="100"/></p>
  
  <h1 align="center" id="heading"> YunoHost LXC </h1>
   
  <a href="https://yunohost.org/#/" target="_blank" rel="noopener noreferrer">YunoHost</a> is an operating system aiming for the simplest administration of a server, and therefore democratize self-hosting, while making sure it stays reliable, secure, ethical and lightweight. 
  
  To create a new Proxmox VE YunoHost LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/yunohost.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 20GB Storage - 2vCPU ⚡</h3>
  
  **YunoHost Interface: IP/**`
  
  },
  {
    "category": "Server - Networking",

    "title": "UniFi Network Server LXC",
    "content": `<p align="center"><img src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fblog.ui.com%2Fwp-content%2Fuploads%2F2016%2F10%2Funifi-app-logo-300x108.png&f=1&nofb=1" height="100"/></p>
  
  <h1 align="center" id="heading"> UniFi Network Server LXC </h1>
  
  <a href="https://www.ui.com/" target="_blank" rel="noopener noreferrer">UniFi Network Server</a> is a software that helps manage and monitor UniFi networks (Wi-Fi, Ethernet, etc.) by providing an intuitive user interface and advanced features. It allows network administrators to configure, monitor, and upgrade network devices, as well as view network statistics, client devices, and historical events. The aim of the application is to make the management of UniFi networks easier and more efficient.
  
  <h4>🛈 CPU with AVX Instruction Set required</h4>
  
  To create a new Proxmox VE UniFi Network Server LXC, run the command below in the <b>Proxmox VE Shell</b>.
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/unifi.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 8GB Storage - 2vCPU ⚡</h3>
  
  **UniFi Interface: (https)IP:8443**`
  
  },
  {
    "category": "Server - Networking",

    "title": "Omada Controller LXC",
    "content": `<p align="center"><img src="https://www.enterpriseitpro.net/wp-content/uploads/2020/12/logo-omada.png" height="100"/></p>
  
  <h1 align="center" id="heading"> Omada Controller LXC </h1>
  
  <a href="https://www.tp-link.com/us/support/download/omada-software-controller/" target="_blank" rel="noopener noreferrer">Omada Controller</a> is a software application used to manage TP-Link's Omada EAP (Enterprise Access Point) devices. It allows administrators to centrally manage a large number of EAPs, monitor network performance, and control user access to the network. The software provides an intuitive interface for network configuration, firmware upgrades, and network monitoring. By using the Omada Controller, administrators can streamline the management process, reduce manual intervention, and improve the overall security and reliability of the network.
  
  To create a new Proxmox VE Omada Controller LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Omada, run the command below (or type update) in the <b>LXC Console</b>.
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/omada.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 8GB Storage - 2vCPU ⚡</h3>
  
  **Omada Interface: (https)IP:8043**`
  
  },
  {
    "category": "Server - Networking",

    "title": "WireGuard LXC",
  
    "content": `<p align="center"><img src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcdn.icon-icons.com%2Ficons2%2F2699%2FPNG%2F512%2Fwireguard_logo_icon_168760.png&f=1&nofb=1" height="100"/></p>
  
  <h1 align="center" id="heading"> WireGuard LXC </h1>
   
  <a href="https://www.wireguard.com/" target="_blank" rel="noopener noreferrer">WireGuard</a> is a free and open-source virtual private network (VPN) software that uses modern cryptography to secure the data transmitted over a network. It is designed to be fast, secure, and easy to use. WireGuard supports various operating systems, including Linux, Windows, macOS, Android, and iOS. It operates at the network layer and is capable of being used with a wide range of protocols and configurations. Unlike other VPN protocols, WireGuard is designed to be simple and fast, with a focus on security and speed. It is known for its ease of setup and configuration, making it a popular choice for personal and commercial use.
  
  To create a new Proxmox VE WireGuard LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>

  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/wireguard.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  512MiB RAM - 4GB Storage - 1vCPU ⚡</h3>
  
  **WGDashboard Interface: IP:10086**

  ⚙️ **Initial Login**

  **username** <code class="highlighter-rouge">admin</code>
  
  **password** <code class="highlighter-rouge">admin</code>`

  },
  {
    "category": "Server - Networking",

    "title": "Cronicle Primary LXC",
    "content": `<p align="center"><img src="https://github.com/jhuckaby/Cronicle/blob/master/htdocs/images/logo-128.png?raw=true" height="100"/></p>
  
  <h1 align="center" id="heading"> Cronicle Primary LXC </h1>
  
  <a href="https://github.com/jhuckaby/Cronicle" target="_blank" rel="noopener noreferrer">Cronicle</a> is a task scheduling and management software that allows users to schedule and run tasks automatically on multiple servers. It has a web-based user interface that provides a convenient and centralized way to manage tasks and view their execution status. With Cronicle, users can schedule tasks to run at specific times, or on demand, and assign tasks to specific worker servers. The software provides real-time statistics and a live log viewer to help users monitor the progress of tasks. Cronicle is designed for use in large-scale environments, making it a valuable tool for automation and management of complex and time-sensitive tasks.
  
  To create a new Proxmox VE Cronicle Primary LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Cronicle or Install Cronicle Worker, run the command below (or type update) in a <b>LXC Console</b>.
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/cronicle.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  512MiB RAM - 2GB Storage - 1vCPU ⚡</h3>
  
  **Cronicle Primary Interface: IP:3012**

  ⚙️ Config Path
  ~~~bash
  /opt/cronicle/conf/config.json
  ~~~

  Primary and Worker Private Keys Must Match.`
  
  },
  {
    "category": "Server - Networking",

    "title": "MeshCentral LXC",
    "content": `<p align="center"><img src="https://github.com/Ylianst/MeshCentral/blob/master/public/favicon-303x303.png?raw=true" height="100"/></p>
  
  <h1 align="center" id="heading"> MeshCentral LXC </h1>
  
  <a href="https://meshcentral.com/" target="_blank" rel="noopener noreferrer">MeshCentral</a> is a web-based computer management platform that provides remote control and management capabilities for computers. It allows administrators to manage and control computers over a local network or the internet through a single, centralized web-based interface. With MeshCentral, users can monitor the status of computers, perform remote administration tasks, and control the power state of machines. The software supports various operating systems and provides real-time updates and alerts to keep administrators informed of the status of their systems. MeshCentral is designed to provide an easy-to-use, scalable, and secure solution for remote computer management, making it a valuable tool for IT administrators, helpdesk support, and remote workers.
  
  To create a new Proxmox VE MeshCentral LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/meshcentral.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  512MiB RAM - 2GB Storage - 1vCPU ⚡</h3>
  
  **MeshCentral Interface: IP**`
  
  },
  {
    "category": "Server - Networking",

    "title": "Headscale LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/headscale.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> Headscale LXC </h1>
  
  <a href="https://headscale.net/" target="_blank" rel="noopener noreferrer">Headscale</a> is an An open source, self-hosted implementation of the Tailscale control server.
  
  To create a new Proxmox VE Headscale LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Headscale, run the command below (or type update) in a <b>LXC Console</b>.
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/headscale.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  512MiB RAM - 2GB Storage - 1vCPU ⚡</h3>
  
  ⚙️ Config Path
  ~~~bash
  /etc/headscale/config.yaml
  ~~~`
  
  },
  {
    "category": "Server - Networking",

    "title": "iVentoy LXC",
    "content": `<p align="center"><img src="https://www.iventoy.com/static/img/iventoy.png" height="100"/></p>
  
  <h1 align="center" id="heading"> iVentoy LXC </h1>
  
  <a href="https://www.iventoy.com/" target="_blank" rel="noopener noreferrer">iVentoy</a> is an enhanced version of the PXE server.
  
  To create a new Proxmox VE iVentoy LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>

  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/iventoy.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  512MiB RAM - 2GB Storage - 1vCPU ⚡</h3>
  
  iVentoy Interface: IP:26000/`
  },
  {
    "category": "Server - Networking",

    "title": "Tailscale",
    "content": `<p align="center"><img src="https://avatars.githubusercontent.com/u/48932923?v=4&s=100"/></p>
  
  <h1 align="center" id="heading"> Tailscale</h1>
  
  <a href="https://tailscale.com/" target="_blank" rel="noopener noreferrer">Tailscale</a> is a software-defined networking solution that enables secure communication between devices over the internet. It creates a virtual private network (VPN) that enables devices to communicate with each other as if they were on the same local network. Tailscale works even when the devices are separated by firewalls or subnets, and provides secure and encrypted communication between devices. With Tailscale, users can connect devices, servers, computers, and cloud instances to create a secure network, making it easier to manage and control access to resources. Tailscale is designed to be easy to set up and use, providing a streamlined solution for secure communication between devices over the internet.
  
  To Install Tailscale on an existing LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/misc/add-tailscale-lxc.sh)"
  ~~~
  After the script finishes, reboot the LXC then run <code class="highlighter-rouge">tailscale up</code> in the LXC console
  
  <a href="https://login.tailscale.com/start" target="_blank" rel="noopener noreferrer">**Tailscale Login**</a>`
  
  },
  {
    "category": "Server - Networking",

    "title": "NetBird",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/netbirdio/netbird/main/docs/media/logo-full.png" height="100"/></p>
  
  <h1 align="center" id="heading"> NetBird</h1>
  
  <a href="https://netbird.io/" target="_blank" rel="noopener noreferrer">NetBird</a> combines a configuration-free peer-to-peer private network and a centralized access control system in a single platform, making it easy to create secure private networks for your organization or home.
  
  To Install NetBird on an existing LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/misc/add-netbird-lxc.sh)"
  ~~~
  After the script finishes, reboot the LXC then run <code class="highlighter-rouge">netbird up</code> in the LXC console
  
  <a href="https://app.netbird.io/?utm_source=landing&utm_medium=home+page&utm_content=navbar+login+link" target="_blank" rel="noopener noreferrer">**NetBird Login**</a>`
  
  },
  {
    "category": "Server - Networking",

    "title": "Caddy LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/caddy.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> Caddy LXC</h1>
  
  <a href="https://caddyserver.com/" target="_blank" rel="noopener noreferrer">Caddy</a> is a powerful, extensible platform to serve your sites, services, and apps, written in Go.
  
  To create a new Proxmox VE Caddy LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/caddy.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  512MiB RAM - 2GB Storage - 1CPU ⚡</h3>`
  
  },
  {
    "category": "Server - Networking",

    "title": "Traefik LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/traefik-gopher.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> Traefik LXC</h1>
  
  <a href="https://traefik.io/" target="_blank" rel="noopener noreferrer">Traefik</a> (pronounced traffic) is an open-source edge router and reverse proxy that simplifies managing microservices. It automatically discovers services, dynamically updates routing rules without downtime, provides load balancing, handles SSL termination, and supports various middleware for added functionality. Ideal for cloud-native environments, it integrates seamlessly with platforms like Docker and Kubernetes.
  
  To create a new Proxmox VE Traefik LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/traefik.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  512MiB RAM - 2GB Storage - 1CPU ⚡</h3>

  **Traefik Interface: IP:8080**`
  },
  {
    "category": "Server - Networking",

    "title": "BunkerWeb LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/bunkerity/bunkerweb/v1.5.7/misc/logo.png" height="100"/></p>
  
  <h1 align="center" id="heading"> BunkerWeb LXC</h1>
  
  <a href="https://www.bunkerweb.io/" target="_blank" rel="noopener noreferrer">BunkerWeb</a> is a next-generation, open-source Web Application Firewall (WAF).
  
  To create a new Proxmox VE BunkerWeb LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update BunkerWeb, run the command below (or type update) in the <b>LXC Console</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/bunkerweb.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 4GB Storage - 2CPU ⚡</h3>

  **BunkerWeb Interface: IP/setup**`
  },
  {
    "category": "Server - Networking",

    "title": "FlareSolverr LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/FlareSolverr/FlareSolverr/master/resources/flaresolverr_logo.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> FlareSolverr LXC</h1>
  
  <a href="https://github.com/FlareSolverr/FlareSolverr" target="_blank" rel="noopener noreferrer">FlareSolverr</a> is a proxy server to bypass Cloudflare and DDoS-GUARD protection.
  
  To create a new Proxmox VE FlareSolverr LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update FlareSolverr, run the command below (or type update) in the <b>LXC Console</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/flaresolverr.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  512MiB RAM - 4GB Storage - 1CPU ⚡</h3>

  **FlareSolverr Interface: IP:8191**`
  },
  {
    "category": "Server - Networking",

    "title": "CrowdSec",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/crowdsecurity/crowdsec-docs/main/crowdsec-docs/static/img/crowdsec_no_txt.png?raw=true" height="100"/></p>
  
  <h1 align="center" id="heading"> CrowdSec</h1>

  <a href="https://crowdsec.net/" target="_blank" rel="noopener noreferrer">CrowdSec</a> is a free and open-source intrusion prevention system (IPS) designed to provide network security against malicious traffic. It is a collaborative IPS that analyzes behaviors and responses to attacks by sharing signals across a community of users. CrowdSec leverages the collective intelligence of its users to detect and respond to security threats in real-time. With CrowdSec, network administrators can set up protection against a wide range of threats, including malicious traffic, bots, and denial-of-service (DoS) attacks. The software is designed to be easy to use and integrate with existing security systems, making it a valuable tool for enhancing the security of any network.
  
  To Install CrowdSec, ⚠️ run the command below in the <b>LXC console</b>.
  
  ~~~bash
    bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/misc/crowdsec.sh)"
  ~~~
  
  [**Control center for your CrowdSec machines.**](https://app.crowdsec.net/product-tour)`
  },
  {
    "category": "Server - Networking",

    "title": "Keycloak LXC",
    "content": `<p align="center"><img src="https://www.keycloak.org/resources/images/logo.svg?raw=true" height="100"/></p>
  
  <h1 align="center" id="heading"> Keycloak LXC</h1>

  <a href="https://www.keycloak.org/" target="_blank" rel="noopener noreferrer">Keycloak</a> is an open-source identity and access management solution that provides centralized authentication and authorization for modern applications and services. It enables organizations to secure their applications and services with a single sign-on (SSO) solution, reducing the need for users to remember multiple login credentials. Keycloak supports various authentication protocols, including SAML, OAuth, and OpenID Connect, and integrates with a wide range of applications and services. With Keycloak, administrators can manage user identities, define security policies, and monitor access to their applications and services. The software is designed to be scalable, flexible, and easy to use, making it a valuable tool for enhancing the security and usability of modern applications and services.
  
  To create a new Proxmox VE Keycloak LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/keycloak.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 4GB Storage - 2CPU ⚡</h3>

  **Keycloak Interface: IP:8080/admin**
  
  [Post Install](https://github.com/tteck/Proxmox/discussions/2922)`
  },
  {
    "category": "Server - Networking",

    "title": "OpenWrt VM",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/openwrt.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> OpenWrt VM </h1>
  
  <a href="https://openwrt.org/" target="_blank" rel="noopener noreferrer">OpenWrt</a>is a powerful open-source firmware that can transform a wide range of networking devices into highly customizable and feature-rich routers, providing users with greater control and flexibility over their network infrastructure.
  
  To create a new Proxmox VE OpenWrt VM, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/vm/openwrt.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  256MiB RAM - 512MiB Storage - 1CPU ⚡</h3>`

  },
  {
    "category": "Server - Networking",

    "title": "lldap LXC",
    "content": `
  
  <h1 align="center" id="heading"> lldap LXC </h1>
  
  <a href="https://github.com/lldap/lldap" target="_blank" rel="noopener noreferrer">lldap</a> is a lightweight authentication server that provides an opinionated, simplified LDAP interface for authentication.
  
  To create a new Proxmox VE V, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/lldap.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  512MiB RAM - 4GB Storage - 1CPU ⚡</h3>

  ⚙️ **Initial Login**

  **username** <code class="highlighter-rouge">admin</code>
  
  **password** <code class="highlighter-rouge">password</code>`
  },
  {
    "category": "Server - Networking",

    "title": "Mikrotik RouterOS CHR VM",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/mikrotik.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> Mikrotik RouterOS CHR VM </h1>
  
  <a href="https://help.mikrotik.com/docs/display/ROS/Cloud+Hosted+Router%2C+CHR" target="_blank" rel="noopener noreferrer">Mikrotik RouterOS CHR</a> is a Linux-based operating system that transforms a computer into a router. It provides a wide range of features for network routing, firewall, bandwidth management, wireless access point, backhaul link, hotspot gateway, VPN server, and many others. RouterOS is a versatile solution that supports various network configurations, including those with multiple WAN links, hotspots, and VPNs. It is highly customizable, allowing administrators to configure and manage their networks according to their specific requirements. With RouterOS, network administrators can monitor and control the performance and security of their networks, ensuring reliable and secure communication for their users. The software is designed to be easy to use and provides a wide range of tools for network management, making it a valuable solution for small and large networks alike.
  
  To create a new Proxmox VE Mikrotik RouterOS CHR VM, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/vm/mikrotik-routeros.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  512MiB RAM - 128MiB Storage - 2CPU ⚡</h3>

  Initial setup is done via VM console. Go to the IP address to manage.

  ⚙️ **Initial Login**

  **username** <code class="highlighter-rouge">admin</code>
  
  **password** <code class="highlighter-rouge">no password</code>`
  },










/* Media - Photo */
  {
    "category": "Media - Photo",

    "title": "Audiobookshelf LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/audiobookshelf.svg" height="100"/></p>
  
  <h1 align="center" id="heading">Audiobookshelf LXC </h1>
  
  <a href="https://www.audiobookshelf.org/" target="_blank" rel="noopener noreferrer">Audiobookshelf</a> is a Self-hosted audiobook and podcast server.
  
  To create a new Proxmox VE Audiobookshelf LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/audiobookshelf.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 4GB Storage - 2vCPU ⚡</h3>
  
  **Audiobookshelf Interface: IP:13378**`
  },
  {
    "category": "Media - Photo",

    "title": "LazyLibrarian LXC",
    "content": `<p align="center"><img src="https://gitlab.com/uploads/-/system/project/avatar/9317860/ll.png" height="100"/></p>
  
  <h1 align="center" id="heading">LazyLibrarian LXC </h1>
  
  <a href="https://gitlab.com/LazyLibrarian/LazyLibrarian" target="_blank" rel="noopener noreferrer">LazyLibrarian</a> is a SickBeard, CouchPotato, Headphones-like application for ebooks, audiobooks and magazines.
  
  To create a new Proxmox VE LazyLibrarian LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update LazyLibrarian, run the command below (or type update) in the <b>LXC Console</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/lazylibrarian.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 4GB Storage - 2vCPU ⚡</h3>
  
  **LazyLibrarian Interface: IP:5299**`
  },
  {
    "category": "Media - Photo",

    "title": "MeTube LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/alexta69/metube/master/ui/src/assets/icons/android-chrome-192x192.png" height="100"/></p>
  
  <h1 align="center" id="heading">MeTube LXC </h1>
  
  <a href="https://github.com/alexta69/metube" target="_blank" rel="noopener noreferrer">MeTube</a> allows you to download videos from YouTube and dozens of other sites.
  
  To create a new Proxmox VE MeTube LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update MeTube, run the command below (or type update) in the <b>LXC Console</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/metube.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 10GB Storage - 1vCPU ⚡</h3>
  
  **MeTube Interface: IP:8081**`
  },
  {
    "category": "Media - Photo",

    "title": "Threadfin LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/Threadfin/Threadfin/main/html/img/threadfin.png" height="100"/></p>
  
  <h1 align="center" id="heading">Threadfin LXC </h1>
  
  <a href="https://github.com/Threadfin/Threadfin" target="_blank" rel="noopener noreferrer">Threadfin</a> is a M3U proxy for Kernel, Plex, Jellyfin, or Emby, based on xTeVe.
  
  To create a new Proxmox VE Threadfin LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Threadfin, run the command below (or type update) in the <b>LXC Console</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/threadfin.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 4GB Storage - 1vCPU ⚡</h3>
  
  **Threadfin Interface: IP:34400/web**`
  },
  {
    "category": "Media - Photo",

    "title": "Plex Media Server LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/plex-white.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> Plex Media Server LXC </h1>
  <h3 align="center" id="heading">With Privileged/Unprivileged Hardware Acceleration Support</h3>
  
  To create a new Proxmox VE Plex Media Server LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Plex Media Server or add Extras, run the command below (or type update) in the <b>LXC Console</b>.

  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/plex.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 8GB Storage - 2vCPU ⚡</h3>
  
  **Plex Media Server Interface: IP:32400/web**`
  
  },
  {
    "category": "Media - Photo",

    "title": "Tautulli LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/tautulli.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> Tautulli LXC </h1>

  <a href="https://tautulli.com/" target="_blank" rel="noopener noreferrer">Tautulli</a> allows you to monitor and track your Plex Media Server usage, such as viewing statistics and analysis of your media library. It can be used to monitor user activity, get notifications about new media added to your library, and even generate reports on your media usage.
  
  To create a new Proxmox VE Tautulli LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>

  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/tautulli.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 4GB Storage - 2vCPU ⚡</h3>
  
  **Tautulli Interface: IP:8181**`
  
  },
  {
    "category": "Media - Photo",

    "title": "Emby Media Server LXC",
    "content": `<p align="center"><img src="https://github.com/home-assistant/brands/blob/master/core_integrations/emby/icon.png?raw=true" height="100"/></p>
  <h1 align="center" id="heading"> Emby Media Server LXC </h1>
  <h3 align="center" id="heading">With Privileged/Unprivileged Hardware Acceleration Support</h3>
  
  <a href="https://emby.media/" target="_blank" rel="noopener noreferrer">Emby</a> brings together your personal videos, music, photos, and live television.
  
  To create a new Proxmox VE Emby Media Server LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Emby, run the command below (or type update) in the <b>LXC Console</b>.
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/emby.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 8GB Storage - 2vCPU ⚡</h3>
  
  **Emby Media Server Interface: IP:8096**`
  
  },
  {
    "category": "Media - Photo",

    "title": "Jellyfin Media Server LXC",
    "content": `<p align="center"><img src="https://github.com/home-assistant/brands/blob/master/core_integrations/jellyfin/icon.png?raw=true" height="100"/></p>
    <h1 align="center" id="heading"> Jellyfin Media Server LXC </h1>
    <h3 align="center" id="heading">With Privileged/Unprivileged Hardware Acceleration Support</h3>

  To create a new Proxmox VE Jellyfin Media Server LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
    bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/jellyfin.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 8GB Storage - 2vCPU ⚡</h3>
  
  **Jellyfin Media Server Interface: IP:8096**
  
  FFmpeg path: <code class="highlighter-rouge">/usr/lib/jellyfin-ffmpeg/ffmpeg</code>`
  
  },
  {
    "category": "Media - Photo",

    "title": "Jellyseerr LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/jellyseerr.svg" height="100"/></p>
  <h1 align="center" id="heading"> Jellyseerr LXC </h1>
  
  <a href="https://github.com/Fallenbagel/jellyseerr" target="_blank" rel="noopener noreferrer">Jellyseerr</a> is a free and open source software application for managing requests for your media library. It is a a fork of Overseerr built to bring support for Jellyfin & Emby media servers.
  
  To create a new Proxmox VE Jellyseerr LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Jellyseerr, run the command below (or type update) in the <b>LXC Console</b>.
 
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/jellyseerr.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 8GB Storage - 2vCPU ⚡</h3>
  
  **Jellyseerr Interface: IP:5055**`
  
  },
  {
    "category": "Media - Photo",

    "title": "Overseerr LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/overseerr.svg" height="100"/></p>
  <h1 align="center" id="heading"> Overseerr LXC </h1>
  
  <a href="https://overseerr.dev/" target="_blank" rel="noopener noreferrer">Overseerr</a> is a request management and media discovery tool built to work with your existing Plex ecosystem.
  
  To create a new Proxmox VE Overseerr LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Overseerr, run the command below (or type update) in the <b>LXC Console</b>.
 
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/overseerr.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 8GB Storage - 2vCPU ⚡</h3>
  
  **Overseerr Interface: IP:5055**`
  
  },
  {
    "category": "Media - Photo",

    "title": "Ombi LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/ombi.svg" height="100"/></p>
  <h1 align="center" id="heading"> Ombi LXC </h1>
  
  <a href="https://ombi.io/" target="_blank" rel="noopener noreferrer">Ombi</a> is a self-hosted web application designed to empower shared Plex, Emby or Jellyfin users with automated content request capabilities. By integrating with various TV Show and Movie DVR tools, Ombi ensures a smooth and comprehensive experience for your users, allowing them to effortlessly request content on their own.
  
  To create a new Proxmox VE Ombi LXC, run the command below in the <b>Proxmox VE Shell</b>.
 
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/ombi.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 4GB Storage - 1vCPU ⚡</h3>
  
  **Ombi Interface: IP:5000**`
  
  },
  {
    "category": "Media - Photo",

    "title": "ownCloud VM",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/owncloud.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> ownCloud VM </h1>

  <a href="https://www.turnkeylinux.org/owncloud" target="_blank" rel="noopener noreferrer">TurnKey ownCloud</a> is an open-source file sharing server and collaboration platform that can store your personal content, like documents and pictures, in a centralized location.
  
  To create a new Proxmox VE TurnKey ownCloud VM, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/vm/owncloud-vm.sh)"
  ~~~

  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 8GB Storage - 2vCPU ⚡</h3>  
  
  **ownCloud Interface: IP**`

  },
  {
    "category": "Media - Photo",

    "title": "Nextcloud LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/nextcloud.svg" height="150"/></p>
  
  <h1 align="center" id="heading">Nextcloud LXC </h1>
  
  <a href="https://github.com/nextcloud/nextcloudpi#features" target="_blank" rel="noopener noreferrer">NextCloudPi</a> is a popular self-hosted solution for file collaboration and data storage. It is built on the NextCloud software, which is an open-source platform for data management.
  
  To create a new Proxmox VE NextCloudPi LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/nextcloudpi.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 8GB Storage - 2vCPU ⚡</h3>

  **NextCloudPi Interface: (https)IP/**<br><br>
  
  <a href="https://nextcloud.com/" target="_blank" rel="noopener noreferrer">Alpine Nextcloud Hub</a> integrates the four key Nextcloud products Files, Talk, Groupware and Office into a single platform, optimizing the flow of collaboration.
  
  To create a new Proxmox VE Alpine Nextcloud Hub LXC, run the command below in the <b>Proxmox VE Shell</b>.<br>
  To Update Alpine Nextcloud or Show Alpine Nextcloud Login Credentials, run the command below in the <b>LXC Console</b>.
  
  ~~~bash
  bash -c "$(wget -qO - https://github.com/tteck/Proxmox/raw/main/ct/alpine-nextcloud.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 2GB Storage - 2vCPU ⚡</h3>

  **Alpine Nextcloud Hub Interface: (https)IP/**<br><br>
  
  <a href="https://www.turnkeylinux.org/nextcloud" target="_blank" rel="noopener noreferrer">TurnKey Nextcloud</a> helps store your files, folders, contacts, photo galleries, calendars and more on a server of your choosing. Access that folder from your mobile device, your desktop, or a web browser. Access your data wherever you are, when you need it.
  
  To create a new Proxmox VE TurnKey Nextcloud LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/turnkey/turnkey.sh)"
  ~~~

  **TurnKey Nextcloud Interface: (https)IP/**`
  },
  {
    "category": "Media - Photo",

    "title": "Owncast LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/owncast/owncast/develop/web/public/img/favicon/android-icon-144x144.png" height="100"/></p>
  
  <h1 align="center" id="heading"> Owncast LXC </h1>
  
  <a href="https://owncast.online/" target="_blank" rel="noopener noreferrer">Owncast</a> is a free and open source live video and web chat server for use with existing popular broadcasting software.
  
  To create a new Proxmox VE Owncast LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/owncast.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 2GB Storage - 2vCPU ⚡</h3>
  
  **Owncast Interface: IP:8080** <br>
  **Owncast Admin Interface: IP:8080/admin**
  
  ⚙️ **Initial Admin Login**
  
  **username** <code class="highlighter-rouge">admin</code>
  
  **password** <code class="highlighter-rouge">abc123</code>`
  },
  {
    "category": "Media - Photo",

    "title": "OpenMediaVault LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/openmediavault.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> OpenMediaVault LXC </h1>
  
  <a href="https://www.openmediavault.org/" target="_blank" rel="noopener noreferrer">OpenMediaVault</a> is a next-generation network-attached storage (NAS) solution based on Debian Linux. It provides a web-based interface for managing and storing digital data, making it easy to use and set up. OpenMediaVault supports various storage protocols, including SMB/CIFS, NFS, and FTP, and provides a wide range of features for data management, such as user and group management, disk quotas, and data backup and recovery. The software is designed to be flexible and scalable, making it a valuable solution for both personal and enterprise use. OpenMediaVault provides a stable and reliable platform for managing and storing digital data, making it a popular choice for those who want to host their own data and ensure its security and privacy. With OpenMediaVault, users can access their data from anywhere and easily share it with others, making it a valuable tool for collaboration and data management.
  
  To create a new Proxmox VE OpenMediaVault LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/omv.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 4GB Storage - 2vCPU ⚡</h3>
  
  **OpenMediaVault Interface: IP**
  
  ⚙️ **Initial Login**
  
  **username** <code class="highlighter-rouge">admin</code>
  
  **password** <code class="highlighter-rouge">openmediavault</code>`
  },
  {
    "category": "Media - Photo",

    "title": "Navidrome LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/navidrome/navidrome/master/resources/logo-192x192.png?raw=true" height="100"/></p>
  
  <h1 align="center" id="heading"> Navidrome LXC </h1>
  
  <a href="https://www.navidrome.org/" target="_blank" rel="noopener noreferrer">Navidrome</a> is a music server solution that makes your music collection accessible from anywhere. It provides a modern web-based user interface and compatibility with a range of third-party mobile apps for both iOS and Android devices. With Navidrome, users can access their music collection from anywhere, whether at home or on the go. The software supports a variety of music formats, making it easy for users to play their favorite songs and albums. Navidrome provides a simple and user-friendly interface for managing and organizing music collections, making it a valuable tool for music lovers who want to access their music from anywhere. The software is designed to be easy to set up and use, making it a popular choice for those who want to host their own music server and enjoy their music collection from anywhere.
  
  To create a new Proxmox VE Navidrome LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Navidrome, run the command below (or type update) in the <b>LXC Console</b>.
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/navidrome.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 4GB Storage - 2vCPU ⚡</h3>
  
  To change Navidrome music folder path, edit: <code class="highlighter-rouge">/var/lib/navidrome/navidrome.toml</code>
  
  **Navidrome Interface: IP:4533**`
  
  },
  {
    "category": "Media - Photo",

    "title": "Petio LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/petio-team/petio/master/frontend/public/p-seamless.png" height="100"/></p>
  
  <h1 align="center" id="heading"> Petio LXC </h1>
  
  <a href="https://petio.tv/" target="_blank" rel="noopener noreferrer">Petio</a> is a third party companion app available to Plex server owners to allow their users to request, review and discover content.
  
  To create a new Proxmox VE Petio LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Petio, run the command below (or type update) in the <b>LXC Console</b>.
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/petio.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 4GB Storage - 2vCPU ⚡</h3>
    
  **Petio Interface: IP:7777**`
  
  },
  {
    "category": "Media - Photo",

    "title": "PhotoPrism LXC",
    "content": `<p align="center"><img src="https://github.com/tteck/Proxmox/blob/main/misc/images/photoprism.png?raw=true" height="100"/></p>
  
  <h1 align="center" id="heading"> PhotoPrism LXC </h1>
  
  <a href="https://photoprism.app/" target="_blank" rel="noopener noreferrer">PhotoPrism</a> is an AI-Powered Photos App for the Decentralized Web. It makes use of the latest technologies to tag and find pictures automatically without getting in your way.
  
  To create a new Proxmox VE PhotoPrism LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update PhotoPrism, run the command below (or type update) in the <b>LXC Console</b>.
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/photoprism.sh)"
  ~~~

  Please note that Ubuntu 22.04 and Debian 12 are supported, while older Linux distributions may not be compatible.

  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 8GB Storage - 2vCPU ⚡</h3>
  
  **PhotoPrism Interface: IP:2342**
  
  ⚙️ **Initial Login**
  
  **username** <code class="highlighter-rouge">admin</code>
  
  **password** <code class="highlighter-rouge">changeme</code>
  
  [PhotoSync](https://www.photosync-app.com/home.html)`
  
  },
  {
    "category": "Media - Photo",

  
    "title": "Medusa LXC",
    "content": `<p align="center"><img src="https://cdn.jsdelivr.net/gh/pymedusa/medusa.github.io@4360d494/images/logo/new-logo.png" height="100"/></p>
  
  <h1 align="center" id="heading">Medusa LXC </h1>
  
  <a href="https://pymedusa.com/" target="_blank" rel="noopener noreferrer">Medusa</a> is an automatic Video Library Manager for TV Shows. It watches for new episodes of your favorite shows, and when they are posted it does its magic: automatic torrent/nzb searching, downloading, and processing at the qualities you want.
  
  To create a new Proxmox VE Medusa LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Medusa, run the command below (or type update) in the <b>LXC Console</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/medusa.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 6GB Storage - 2vCPU ⚡</h3>
  
  **Medusa Interface: IP:8081**`
  },
  {
    "category": "Media - Photo",

  
    "title": "Bazarr LXC",
    "content": `<p align="center"><img src="https://www.bazarr.media/assets/img/logo.png" height="100"/></p>
  
  <h1 align="center" id="heading">Bazarr LXC </h1>
  
  <a href="https://www.bazarr.media/" target="_blank" rel="noopener noreferrer">Bazarr</a> is a companion application to Sonarr and Radarr that manages and downloads subtitles based on your requirements.
  
  To create a new Proxmox VE Bazarr LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/bazarr.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 4GB Storage - 2vCPU ⚡</h3>
  
  **Bazarr Interface: IP:6767**`
  },
  {
    "category": "Media - Photo",

  
    "title": "Lidarr LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/Lidarr/Lidarr/develop/Logo/256.png" height="100"/></p>
  
  <h1 align="center" id="heading">Lidarr LXC </h1>
  
  <a href="https://lidarr.audio/" target="_blank" rel="noopener noreferrer">Lidarr</a> is a music management tool designed for Usenet and BitTorrent users. It allows users to manage and organize their music collection with ease. Lidarr integrates with popular Usenet and BitTorrent clients, such as Sonarr and Radarr, to automate the downloading and organizing of music files. The software provides a web-based interface for managing and organizing music, making it easy to search and find songs, albums, and artists. Lidarr also supports metadata management, including album art, artist information, and lyrics, making it easy for users to keep their music collection organized and up-to-date. The software is designed to be easy to use and provides a simple and intuitive interface for managing and organizing music collections, making it a valuable tool for music lovers who want to keep their collection organized and up-to-date. With Lidarr, users can enjoy their music collection from anywhere, making it a powerful tool for managing and sharing music files.
  
  To create a new Proxmox VE Lidarr LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/lidarr.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 4GB Storage - 2vCPU ⚡</h3>
  
  **Lidarr Interface: IP:8686**`
  },
  {
    "category": "Media - Photo",

  
    "title": "Prowlarr LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/Prowlarr/Prowlarr/develop/Logo/256.png" height="100"/></p>
  
  <h1 align="center" id="heading">Prowlarr LXC </h1>
  
  <a href="https://github.com/Prowlarr/Prowlarr" target="_blank" rel="noopener noreferrer">Prowlarr</a> is a software tool designed to integrate with various PVR (Personal Video Recorder) apps. It is built on a popular *arr .net/ReactJS base stack and serves as an indexer manager and proxy. Prowlarr makes it easy to manage and organize TV show and movie collections, by integrating with popular PVR apps and automating the downloading and organizing of media files. The software provides a web-based interface for managing and organizing TV shows and movies, making it easy to search and find content. Prowlarr also supports metadata management, including show and movie information, making it easy for users to keep their media collection organized and up-to-date. The software is designed to be easy to use and provides a simple and intuitive interface for managing and organizing media collections, making it a valuable tool for media enthusiasts who want to keep their collection organized and up-to-date. With Prowlarr, users can enjoy their media collection from anywhere, making it a powerful tool for managing and sharing media files.
  
  To create a new Proxmox VE Prowlarr LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/prowlarr.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 4GB Storage - 2vCPU ⚡</h3>
  
  **Prowlarr Interface: IP:9696**`
  },
  {
    "category": "Media - Photo",

  
    "title": "Radarr LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/Radarr/Radarr/develop/Logo/256.png" height="100"/></p>
  
  <h1 align="center" id="heading">Radarr LXC </h1>
  
  <a href="https://radarr.video/" target="_blank" rel="noopener noreferrer">Radarr</a> is a movie management tool designed for Usenet and BitTorrent users. It allows users to manage and organize their movie collection with ease. Radarr integrates with popular Usenet and BitTorrent clients, such as Sonarr and Lidarr, to automate the downloading and organizing of movie files. The software provides a web-based interface for managing and organizing movies, making it easy to search and find titles, genres, and release dates. Radarr also supports metadata management, including movie posters and information, making it easy for users to keep their movie collection organized and up-to-date. The software is designed to be easy to use and provides a simple and intuitive interface for managing and organizing movie collections, making it a valuable tool for movie enthusiasts who want to keep their collection organized and up-to-date. With Radarr, users can enjoy their movie collection from anywhere, making it a powerful tool for managing and sharing movie files.
  
  To create a new Proxmox VE Radarr LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/radarr.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 4GB Storage - 2vCPU ⚡</h3>
  
  **Radarr Interface: IP:7878**`
  },
  {
    "category": "Media - Photo",

  
    "title": "Readarr LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/Readarr/Readarr/develop/Logo/256.png" height="100"/></p>
  
  <h1 align="center" id="heading">Readarr LXC </h1>
  
  <a href="https://readarr.com/" target="_blank" rel="noopener noreferrer">Readarr</a> is an eBook and audiobook management tool designed for Usenet and BitTorrent users. It allows users to manage and organize their eBook and audiobook collection with ease. Readarr integrates with popular Usenet and BitTorrent clients, such as Sonarr and Lidarr, to automate the downloading and organizing of eBook and audiobook files. The software provides a web-based interface for managing and organizing eBooks and audiobooks, making it easy to search and find titles, authors, and genres. Readarr also supports metadata management, including cover art and information, making it easy for users to keep their eBook and audiobook collection organized and up-to-date. The software is designed to be easy to use and provides a simple and intuitive interface for managing and organizing eBook and audiobook collections, making it a valuable tool for book and audiobook enthusiasts who want to keep their collection organized and up-to-date. With Readarr, users can enjoy their eBook and audiobook collection from anywhere, making it a powerful tool for managing and sharing book and audiobook files.
  
  To create a new Proxmox VE Radarr LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/readarr.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 4GB Storage - 2vCPU ⚡</h3>
  
  **Readarr Interface: IP:8787**`
  },
  {
    "category": "Media - Photo",

  
    "title": "Sonarr LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/Sonarr/Sonarr/develop/Logo/256.png" height="100"/></p>
  
  <h1 align="center" id="heading">Sonarr LXC </h1>
  
  <a href="https://sonarr.tv/" target="_blank" rel="noopener noreferrer">Sonarr</a> is a personal video recorder (PVR) software designed for Usenet and BitTorrent users. It allows users to manage and organize their TV show collection with ease. Sonarr integrates with popular Usenet and BitTorrent clients, such as NZBget and Transmission, to automate the downloading and organizing of TV show files. The software provides a web-based interface for managing and organizing TV shows, making it easy to search and find titles, seasons, and episodes. Sonarr also supports metadata management, including TV show posters and information, making it easy for users to keep their TV show collection organized and up-to-date. The software is designed to be easy to use and provides a simple and intuitive interface for managing and organizing TV show collections, making it a valuable tool for TV show enthusiasts who want to keep their collection organized and up-to-date. With Sonarr, users can enjoy their TV show collection from anywhere, making it a powerful tool for managing and sharing TV show files.
  
  To create a new Proxmox VE Sonarr LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Sonarr, run the command below (or type update) in the <b>LXC Console</b>.
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/sonarr.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 4GB Storage - 2vCPU ⚡</h3>
  
  **Sonarr Interface: IP:8989**`
  },
  {
    "category": "Media - Photo",

    "title": "Unmanic LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/Unmanic/unmanic/master/icon.png" height="100"/></p>
  
  <h1 align="center" id="heading"> Unmanic LXC </h1>
  
  <a href="https://docs.unmanic.app/" target="_blank" rel="noopener noreferrer">Unmanic</a> is a simple tool for optimising your file library. You can use it to convert your files into a single, uniform format, manage file movements based on timestamps, or execute custom commands against a file based on its file size.
  
  To create a new Proxmox VE Unmanic LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Unmanic, run the command below (or type update) in the <b>LXC Console</b>.
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/unmanic.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 4GB Storage - 2vCPU - Privileged ⚡</h3>
  
  **Unmanic Interface: IP:8888**`
  
  },
  {
    "category": "Media - Photo",

  
    "title": "Tdarr LXC",
    "content": `<p align="center"><img src="https://home.tdarr.io/static/media/logo3-min.246d6df44c7f16ddebaf.png" height="100"/></p>
  
  <h1 align="center" id="heading">Tdarr LXC </h1>
  
  <a href="https://tdarr.io/" target="_blank" rel="noopener noreferrer">Tdarr</a> is a media transcoding application designed to automate the transcode and remux management of a media library. It uses conditional-based processing to determine the required encoding and remux operations for each file in the library. The software integrates with popular media management tools, such as Sonarr and Radarr, to ensure that newly added media files are automatically processed and optimized for the user's desired playback device. Tdarr provides a web-based interface for monitoring and managing the transcoding process, and also supports real-time logging and reporting. The software is designed to be flexible and configurable, with a wide range of encoding and remux options available to users. Tdarr is an ideal solution for media enthusiasts who want to optimize their library for seamless playback on a variety of devices, while also streamlining the management and maintenance of their media library.
  
  To create a new Proxmox VE Tdarr LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/tdarr.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 4GB Storage - 2vCPU ⚡</h3>
  
  **Tdarr Interface: IP:8265**`
  },
  {
    "category": "Media - Photo",

  
    "title": "ErsatzTV LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/ErsatzTV/ErsatzTV/main/artwork/ersatztv-logo.svg" height="100"/></p>
  
  <h1 align="center" id="heading">ErsatzTV LXC </h1>
  
  <a href="https://ersatztv.org/" target="_blank" rel="noopener noreferrer">ErsatzTV</a> is software for configuring and streaming custom live channels using your media library.
  
  To create a new Proxmox VE ErsatzTV LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update ErsatzTV, run the command below (or type update) in the <b>LXC Console</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/ersatztv.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 5GB Storage - 1vCPU ⚡</h3>
  
  **ErsatzTV Interface: IP:8409**`
  },
  {
    "category": "Media - Photo",

  
    "title": "Whisparr LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/Whisparr/Whisparr/develop/Logo/256.png" height="100"/></p>
  
  <h1 align="center" id="heading">Whisparr LXC </h1>
  
  <a href="https://github.com/Whisparr/Whisparr" target="_blank" rel="noopener noreferrer">Whisparr</a> is an adult movie collection manager for Usenet and BitTorrent users.
  
  To create a new Proxmox VE Whisparr LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/whisparr.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 4GB Storage - 2vCPU ⚡</h3>
  
  **Whisparr Interface: IP:6969**`
  },
  {
    "category": "Media - Photo",

  
    "title": "MediaMTX LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/bluenviron/mediamtx/main/logo.png" height="100"/></p>
  
  <h1 align="center" id="heading">MediaMTX LXC </h1>
  
  <a href="https://github.com/bluenviron/mediamtx" target="_blank" rel="noopener noreferrer">MediaMTX</a> is a ready-to-use SRT / WebRTC / RTSP / RTMP / LL-HLS media server and media proxy that allows you to read, publish, proxy, record and playback video and audio streams.
  
  To create a new Proxmox VE MediaMTX LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/mediamtx.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 4GB Storage - 2vCPU ⚡</h3>
  
  **MediaMTX Interface: NONE** <a href="https://github.com/bluenviron/mediamtx/blob/main/README.md" target="_blank" rel="noopener noreferrer">Instructions</a>`
  },
  {
    "category": "Media - Photo",

    "title": "Calibre-Web LXC",
    "content": `<p align="center"><img src="" height="100"/></p>
  
  <h1 align="center" id="heading"> Calibre-Web LXC </h1>
  
  <a href="https://github.com/janeczku/calibre-web" target="_blank" rel="noopener noreferrer">Calibre-Web</a> is a web app for browsing, reading and downloading eBooks stored in a Calibre database.
  
  To create a new Proxmox VE Calibre-Web LXC, run the command below in the <b>Proxmox VE Shell</b>.<br>
  To Update Calibre-Web or Add Calibre-Web Extras, run the command below in the <b>LXC Console</b>
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/calibre-web.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 4GB Storage - 2vCPU ⚡</h3>
  
  **Calibre-Web Interface: IP:8083** <br>
  
  ⚙️ **Initial Login**
  
  **username** <code class="highlighter-rouge">admin</code>
  
  **password** <code class="highlighter-rouge">admin123</code>`
  },










/* NVR - DVR */
  {
    "category": "NVR - DVR",

    "title": "Scrypted LXC",
    "content": `<p align="center"><img src="https://www.scrypted.app/images/web_hi_res_512.png?raw=true" height="100"/></p>
  <h1 align="center" id="heading">Scrypted LXC</h1>
  
  <a href="https://www.scrypted.app/" target="_blank" rel="noopener noreferrer">Scrypted</a> focuses on providing a seamless experience for managing and utilizing cameras in a smart home setup. It offers features like camera management, event triggering, video and image storage, and integration with other smart home devices and services. Scrypted is designed to make it easy to set up and use cameras in a home automation system, providing a simple and user-friendly interface for monitoring and automating camera-related tasks.
  
  <h4>🛈 If the LXC is created Privileged, the script will automatically set up USB passthrough.</h4>
  
  To create a new Proxmox VE Scrypted LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/scrypted.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 8GB Storage - 2vCPU ⚡</h3>
    
  **Scrypted Interface: (https)IP:10443**`
  
  },  
  {
    "category": "NVR - DVR",

  
    "title": "Frigate LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/frigate.svg" height="100"/></p>
  
  <h1 align="center" id="heading">Frigate LXC </h1>
  <h3 align="center" id="heading">With Privileged/Unprivileged Hardware Acceleration Support</h3>
  
  <a href="https://frigate.video/" target="_blank" rel="noopener noreferrer">Frigate</a> is an open source NVR built around real-time AI object detection. All processing is performed locally on your own hardware, and your camera feeds never leave your home.
  
  To create a new Proxmox VE Frigate LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/frigate.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 20GB Storage - 4vCPU - Privileged ⚡</h3>
  
  **Frigate Interface: IP:5000**
  
  **go2rtc Interface: IP:1984**`
  },
  {
    "category": "NVR - DVR",

    "title": "AgentDVR LXC",
    "content": `<p align="center"><img src="https://ispycontent.azureedge.net/img/ispy2.png?raw=true" height="100"/></p>
  
  <h1 align="center" id="heading"> AgentDVR LXC </h1>

  <a href="https://www.ispyconnect.com/" target="_blank" rel="noopener noreferrer">AgentDVR</a> a new video surveillance solution for the Internet Of Things.
  
  To create a new Proxmox VE AgentDVR LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>

  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/agentdvr.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 8GB Storage - 2vCPU - Privileged ⚡</h3>
  
  **AgentDVR Interface: IP:8090**`
  
  },
  {
    "category": "NVR - DVR",

    "title": "Shinobi NVR LXC",
    "content": `<p align="center"><img src="https://gitlab.com/uploads/-/system/project/avatar/6947723/mstile-150x150.png?raw=true" height="100"/></p>
  
  <h1 align="center" id="heading"> Shinobi NVR LXC </h1>

  <a href="https://shinobi.video/" target="_blank" rel="noopener noreferrer">Shinobi</a> is an open-source, self-hosted network video recording (NVR) software. It allows you to manage and monitor security cameras and record video footage. Shinobi can be run on various platforms including Linux, macOS, and Raspberry Pi, and offers features such as real-time streaming, motion detection, and email notifications.
  
  To create a new Proxmox VE Shinobi NVR LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Shinobi, run the command below (or type update) in the <b>LXC Console</b>.
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/shinobi.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 8GB Storage - 2vCPU - Privileged ⚡</h3>
  
  **Shinobi Interface: IP:8080**

  **Shinobi Admin Interface: IP:8080/super**
  
  ⚙️ **Initial Admin Login**
  
  **username** <code class="highlighter-rouge">admin@shinobi.video</code>
  
  **password** <code class="highlighter-rouge">admin</code>`
  
  },
  {
    "category": "NVR - DVR",

    "title": "MotionEye NVR LXC",
    "content": `<p align="center"><img src="https://github.com/home-assistant/brands/blob/master/core_integrations/motioneye/icon.png?raw=true" height="100"/></p>
  
  <h1 align="center" id="heading"> MotionEye NVR LXC </h1>

  MotionEye is an open-source, self-hosted network video recording (NVR) software designed to manage and monitor IP cameras. It runs on various platforms such as Linux, Raspberry Pi, and Docker, and offers features such as real-time video streaming, motion detection, and customizable camera views.
  
  To create a new Proxmox VE MotionEye NVR LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update MotionEye, run the command below (or type update) in the <b>LXC Console</b>.
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/motioneye.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 8GB Storage - 2vCPU ⚡</h3>
  
  **MotionEye Interface: IP:8765**
  
  ⚙️ **Initial Login**
  
  **username** <code class="highlighter-rouge">admin</code>
  
  **password** <code class="highlighter-rouge">    </code>`
  
},
  {
    "category": "NVR - DVR",

  
    "title": "Channels DVR Server LXC",
    "content": `<p align="center"><img src="https://getchannels.com/a/images/channels-logo.svg" height="100"/></p>
  
  <h1 align="center" id="heading">Channels DVR Server LXC </h1>
  
  <a href="https://getchannels.com/dvr-server/" target="_blank" rel="noopener noreferrer">Channels DVR Server</a> runs on your computer or NAS device at home. There's no cloud to worry about. Your tv shows and movies will always be available.
  
  To create a new Proxmox VE Channels DVR Server LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/channels.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 8GB Storage - 2vCPU - Privileged ⚡</h3>
  
  **Channels DVR Server Interface: IP:8089**`
  },










/* Ad Blocker - DNS */
  {
    "category": "Ad Blocker - DNS",

    "title": "Pi-hole LXC",
    "content": `<p align="center"><img src="https://github.com/home-assistant/brands/blob/master/core_integrations/pi_hole/icon.png?raw=true" height="100"/></p>
  
  <h1 align="center" id="heading"> Pi-hole LXC </h1>
  <h3 align="center" id="heading">With an option to add Unbound</h3>  
  <a href="https://pi-hole.net/" target="_blank" rel="noopener noreferrer">Pi-hole</a> is a free, open-source network-level advertisement and Internet tracker blocking application. It runs on a Raspberry Pi or other Linux-based systems and acts as a DNS sinkhole, blocking unwanted traffic before it reaches a user's device. Pi-hole can also function as a DHCP server, providing IP addresses and other network configuration information to devices on a network. The software is highly configurable and supports a wide range of customizations, such as allowing or blocking specific domains, setting up blocklists and whitelists, and customizing the appearance of the web-based interface. The main purpose of Pi-hole is to protect users' privacy and security by blocking unwanted and potentially malicious content, such as ads, trackers, and malware. It is designed to be easy to set up and use, and can be configured through a web-based interface or through a terminal-based command-line interface.
  
  To create a new Proxmox VE Pi-hole LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Pi-hole, run the command below (or type update) in the <b>LXC Console</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/pihole.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  512MiB RAM - 2GB Storage - 1vCPU ⚡</h3>
  
  ⚠️ **Reboot Pi-hole LXC after install**
  
  **Pi-hole Interface: IP/admin**
  
  ⚙️ **To set your password:**
  
  ~~~yaml
  pihole -a -p
  ~~~`
  },
  {
    "category": "Ad Blocker - DNS",

    "title": "Technitium DNS LXC",
    "content": `<p align="center"><img src="https://avatars.githubusercontent.com/u/12230362?s=100&v=4" height="100"/></p>
  
  <h1 align="center" id="heading"> Technitium DNS LXC </h1>

  <a href="https://technitium.com/dns/" target="_blank" rel="noopener noreferrer">Technitium DNS Server</a> is a free, open-source and privacy-focused DNS (Domain Name System) server software for Windows, Linux, and macOS. It is designed to provide a secure, fast, and reliable DNS resolution service to its users. The server can be configured through a web-based interface, and it supports a variety of advanced features, such as automatic IP updates, IPv6 support, caching of DNS queries, and the ability to block unwanted domains. It is also designed to be highly secure, with built-in measures to prevent common types of DNS attacks and data leaks. Technitium DNS Server is aimed at providing an alternative to traditional DNS servers, which often have privacy and security concerns associated with them, and it is ideal for users who are looking for a more secure and private DNS resolution service.
  
  To create a new Proxmox VE Technitium DNS LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Technitium DNS, run the command below (or type update) in the <b>LXC Console</b>.
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/technitiumdns.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  512MiB RAM - 2GB Storage - 1vCPU ⚡</h3>
  
  **Technitium DNS Interface: IP:5380**`
  
  },
  {
    "category": "Ad Blocker - DNS",

    "title": "AdGuard Home LXC",
    "content": `<p align="center"><img src="https://github.com/home-assistant/brands/blob/master/core_integrations/adguard/icon.png?raw=true" height="100"/></p>
  
  <h1 align="center" id="heading"> AdGuard Home LXC </h1>

  <a href="https://adguard.com/en/adguard-home/overview.html" target="_blank" rel="noopener noreferrer">AdGuard Home</a> is an open-source, self-hosted network-wide ad blocker. It blocks advertisements, trackers, phishing and malware websites, and provides protection against online threats. AdGuard Home is a DNS-based solution, which means it blocks ads and malicious content at the network level, before it even reaches your device. It runs on your home network and can be easily configured and managed through a web-based interface. It provides detailed statistics and logs, allowing you to see which websites are being blocked, and why. AdGuard Home is designed to be fast, lightweight, and easy to use, making it an ideal solution for home users who want to block ads, protect their privacy, and improve the speed and security of their online experience.
  
  To create a new Proxmox VE AdGuard Home LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Manually Update AdGuard Home, run the command below (or type update) in the <b>LXC Console</b>.
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/adguard.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  512MiB RAM - 2GB Storage - 1vCPU ⚡</h3>

  **AdGuard Home Setup Interface: IP:3000  (After Setup use only IP)**
  
  <sub>(For the Home Assistant Integration, use port <code class="highlighter-rouge">80</code> not <code class="highlighter-rouge">3000</code>)</sub>`
  
  },
  {
    "category": "Ad Blocker - DNS",

    "title": "Blocky LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/0xERR0R/blocky/main/docs/blocky.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> Blocky LXC </h1>
  
  <a href="https://0xerr0r.github.io/blocky/" target="_blank" rel="noopener noreferrer">Blocky</a> is a software tool designed for blocking unwanted ads and trackers on local networks. It functions as a DNS proxy and runs on the Go programming language. Blocky intercepts requests to advertisements and other unwanted content and blocks them before they reach the end user. This results in a cleaner, faster, and more secure online experience for users connected to the local network. Blocky is open-source, easy to configure and can be run on a variety of devices, making it a versatile solution for small to medium-sized local networks.
  
  To create a new Proxmox VE Blocky LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/blocky.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  512MiB RAM - 2GB Storage - 1vCPU ⚡</h3>
  
  
  ⚙️ **Blocky Config Path**
  
  ~~~yaml
  /opt/blocky/config.yml
  ~~~`
  },










/* Document - Notes */
  {
    "category": "Document - Notes",

    "title": "Paperless-ngx LXC",
    "content": `<p align="center"><img src="https://github.com/paperless-ngx/paperless-ngx/blob/main/resources/logo/web/svg/square.svg?raw=true" height="100"/></p>
  
  <h1 align="center" id="heading"> Paperless-ngx LXC </h1>
  
  <a href="https://paperless-ngx.readthedocs.io/en/latest/#" target="_blank" rel="noopener noreferrer">Paperless-ngx</a> is a software tool designed for digitizing and organizing paper documents. It provides a web-based interface for scanning, uploading, and organizing paper documents, making it easier to manage, search, and access important information. Paperless-ngx uses the OCR (Optical Character Recognition) technology to extract text from scanned images and makes it searchable, thus increasing the efficiency of document management.
  
  To create a new Proxmox VE Paperless-ngx LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Paperless-ngx or Show Login Credentials, run the command below (or type update) in the <b>LXC Console</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/paperless-ngx.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 8GB Storage - 2vCPU ⚡</h3>
  
  **Paperless-ngx Interface: IP:8000**`
  
  },
  {
    "category": "Document - Notes",

    "title": "Stirling-PDF LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/Stirling-Tools/Stirling-PDF/main/docs/stirling-pdf.png" height="100"/></p>
  
  <h1 align="center" id="heading"> Stirling-PDF LXC </h1>
  
  <a href="https://github.com/Stirling-Tools/Stirling-PDF" target="_blank" rel="noopener noreferrer">Stirling-PDF</a> is a powerful locally hosted web based PDF manipulation tool that allows you to perform various operations on PDF files, such as splitting merging, converting, reorganizing, adding images, rotating, compressing, and more.
  
  To create a new Proxmox VE Stirling-PDF LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Stirling-PDF, run the command below (or type update) in the <b>LXC Console</b>.
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/stirling-pdf.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 8GB Storage - 2vCPU ⚡</h3>
  
  **Stirling-PDF Interface: IP:8080**`
  
  },
  {
    "category": "Document - Notes",

    "title": "Trilium LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/zadam/trilium/master/images/app-icons/png/128x128.png?raw=true" height="100"/></p>
  
  <h1 align="center" id="heading"> Trilium LXC </h1>
  
  <a href="https://github.com/zadam/trilium#trilium-notes" target="_blank" rel="noopener noreferrer">Trilium</a> is an open-source note-taking and personal knowledge management application. It allows users to organize and manage their notes, ideas, and information in a single place, using a hierarchical tree-like structure. Trilium offers a range of features, including rich text formatting, links, images, and attachments, making it easy to create and structure notes. The software is designed to be flexible and customizable, with a range of customization options and plugins available, including themes, export options, and more. Trilium is a self-hosted solution, and can be run on a local machine or a cloud-based server, providing users with full control over their notes and information.
  
  To create a new Proxmox VE Trilium LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Trilium, run the command below (or type update) in the <b>LXC Console</b>.
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/trilium.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  512MiB RAM - 2GB Storage - 1vCPU ⚡</h3>
  
  **Trilium Interface: IP:8080**`
  
  },
  {
    "category": "Document - Notes",

    "title": "Wiki.js LXC",
    "content": `<p align="center"><img src="https://static.requarks.io/logo/wikijs-butterfly.svg?raw=true" height="100"/></p>
  
  <h1 align="center" id="heading"> Wiki.js LXC </h1>
  
  <a href="https://js.wiki/" target="_blank" rel="noopener noreferrer">Wiki.js</a> is a free, open-source, and modern wiki application built using Node.js. It is designed to be fast, easy to use, and flexible, with a range of features for collaboration, knowledge management, and content creation. Wiki.js supports Markdown syntax for editing pages, and includes features such as version control, page history, and access control, making it easy to manage content and collaborate with others. The software is fully customizable, with a range of themes and extensions available, and can be deployed on a local server or in the cloud, making it an ideal choice for small teams and organizations looking to create and manage a wiki. Wiki.js provides a modern, user-friendly interface, and supports a range of data sources, including local file systems, databases, and cloud storage services. 
  
  To create a new Proxmox VE Wiki.js LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Wiki.js, run the command below (or type update) in the <b>LXC Console</b>.
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/wikijs.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  512MiB RAM - 2GB Storage - 1vCPU ⚡</h3>
  
  **Wiki.js Interface: IP:3000**`
  },
  {
    "category": "Document - Notes",

  
    "title": "NocoDB LXC",
    "content": `<p align="center"><img src="https://github.com/tteck/Proxmox/blob/main/misc/images/nocodb.png?raw=true" height="100"/></p>
  
  <h1 align="center" id="heading"> NocoDB LXC </h1>
  
  <a href="https://www.nocodb.com/" target="_blank" rel="noopener noreferrer">NocoDB</a> is a document-oriented database management system. It uses the NoSQL (Not Only SQL) data model, which allows for more flexible and scalable data storage than traditional relational databases. NoCoDB stores data in JSON format, making it easier to manage and query complex data structures, and supports a range of data types, including strings, numbers, arrays, and objects. The software provides a web-based interface for managing and querying data, and includes features such as real-time data synchronization, auto-indexing, and full-text search. NoCoDB is designed to be scalable, and can be used for a range of applications, from small projects to large enterprise systems. The software is free and open-source, and is designed to be easy to use and integrate with other applications.
  
  To create a new Proxmox VE NocoDB LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update NocoDB, run the command below (or type update) in the <b>LXC Console</b>.
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/nocodb.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 4GB Storage - 1vCPU ⚡</h3>
  
  **NocoDB Interface: IP:8080/dashboard**`
  
  },
  {
    "category": "Document - Notes",
  
    "title": "Kavita LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/Kareadita/Kavita/develop/Logo/kavita.svg" height="100"/></p>
  
  <h1 align="center" id="Kavita"> Kavita LXC </h1>
  
  <a href="https://www.kavitareader.com/" target="_blank" rel="noopener noreferrer">Kavita</a> is a fast, feature rich, cross platform reading server. Built with a focus for manga, and the goal of being a full solution for all your reading needs.
  
  To create a new Proxmox VE Kavita LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Kavita, run the command below (or type update) in the <b>LXC Console</b>.
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/kavita.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 8GB Storage - 2vCPU ⚡</h3>
  
  **Kavita Interface: IP:5000**`
  
  },










/* Dashboards */
  {
    "category": "Dashboards",

    "title": "Heimdall Dashboard LXC",
    "content": `<p align="center"><img src="https://github.com/tteck/Proxmox/blob/main/misc/images/heimdall.png?raw=true" height="100"/></p>
  
  <h1 align="center" id="heading"> Heimdall Dashboard LXC </h1>
  
  <a href="https://heimdall.site/" target="_blank" rel="noopener noreferrer">Heimdall Dashboard</a> is a self-hosted, web-based dashboard for managing and monitoring the health of applications and servers. It allows you to keep track of the status of your systems from a single, centralized location, and receive notifications when things go wrong. With Heimdall Dashboard, you have full control over your data and can customize it to meet your specific needs. Self-hosting the dashboard gives you the flexibility to run it on your own infrastructure, making it a suitable solution for organizations that prioritize data security and privacy.
  
  To create a new Proxmox VE Heimdall Dashboard LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Heimdall Dashboard, run the command below (or type update) in the <b>LXC Console</b>.
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/heimdall-dashboard.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  512MiB RAM - 2GB Storage - 1vCPU ⚡</h3>
  
  **Heimdall Dashboard Interface: IP:7990**`
  
  },
  {
    "category": "Dashboards",

    "title": "Homarr LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/homarr.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> Homarr LXC </h1>
  
  <a href="https://homarr.dev/" target="_blank" rel="noopener noreferrer">Homarr</a> is a sleek, modern dashboard that puts all of your apps and services at your fingertips.
  
  To create a new Proxmox VE Homarr LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Homarr, run the command below (or type update) in the <b>LXC Console</b>.
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/homarr.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 8GB Storage - 2vCPU ⚡</h3>
  
  **Homarr Interface: IP:3000**`
  
  },
  {
    "category": "Dashboards",

    "title": "Linkwarden LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/linkwarden/linkwarden/main/assets/logo.png" height="100"/></p>
  
  <h1 align="center" id="heading"> Linkwarden LXC </h1>
  
  <a href="https://linkwarden.app/" target="_blank" rel="noopener noreferrer">Linkwarden</a> is a fully self-hostable, open-source collaborative bookmark manager to collect, organize and archive webpages.
  
  To create a new Proxmox VE Linkwarden LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>

  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/linkwarden.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 8GB Storage - 2vCPU ⚡</h3>
  
  **Linkwarden Interface: IP:3000**
  
  Show Database/Adminer Credentials: <code class="highlighter-rouge">cat linkwarden.creds</code>`
  
  },
  {
    "category": "Dashboards",

    "title": "Homepage LXC",
    "content": `<p align="center"><img src="https://github.com/benphelps/homepage/raw/main/images/banner_light%402x.png?raw=true" height="100"/></p>
  
  <h1 align="center" id="heading"> Homepage LXC </h1>
  
  <a href="https://github.com/benphelps/homepage" target="_blank" rel="noopener noreferrer">Homepage</a> is a self-hosted dashboard solution for centralizing and organizing data and information.
  
  To create a new Proxmox VE Homepage LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Homepage, run the command below (or type update) in the <b>LXC Console</b>.
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/homepage.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 3GB Storage - 2vCPU ⚡</h3>
  
  [Configuration](https://gethomepage.dev/latest/configs/) (bookmarks.yaml, services.yaml, widgets.yaml) path: <code class="highlighter-rouge">/opt/homepage/config/</code>
  
  **Homepage Interface: IP:3000**`
  
  },
  {
    "category": "Dashboards",

    "title": "Fenrus LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/revenz/Fenrus/master/wwwroot/fenrus.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> Fenrus LXC </h1>
  
  <a href="https://github.com/revenz/Fenrus" target="_blank" rel="noopener noreferrer">Fenrus</a> is a personal home page for quick access to all your personal apps/sites.
  
  To create a new Proxmox VE Fenrus LXC, run the command below in the <b>Proxmox VE Shell</b>.

  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/fenrus.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  512MB RAM - 4GB Storage - 1vCPU ⚡</h3>

  **Fenrus Interface: IP:5000**`
  
  },
  {
    "category": "Dashboards",

    "title": "OliveTin",
    "content": `<p align="center"><img src="https://www.olivetin.app/resources/images/logo.png" height="100"/></p>
  
  <h1 align="center" id="heading"> OliveTin </h1>

  <a href="https://www.olivetin.app/" target="_blank" rel="noopener noreferrer">OliveTin</a> provides a secure and straightforward way to execute pre-determined shell commands through a web-based interface.
  
  To Install OliveTin, ⚠️ run the command below in the LXC console.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/misc/olivetin.sh)"
  ~~~
  
  **OliveTin Interface: IP:1337**
  
  ⚙️ **Config Path**
  
  ~~~yaml
  /etc/OliveTin/config.yaml
  ~~~`
  },
  {
    "category": "Dashboards",

    "title": "Mafl LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/hywax/mafl/main/docs/public/logotype.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> Mafl LXC </h1>
  
  <a href="https://mafl.hywax.space/" target="_blank" rel="noopener noreferrer">Mafl</a> is an intuitive service for organizing your homepage. Customize Mafl to your individual needs and work even more efficiently!
  
  To create a new Proxmox VE Mafl LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Mafl, run the command below (or type update) in the <b>LXC Console</b>.
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/mafl.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 6GB Storage - 1vCPU ⚡</h3>

  **Mafl Interface: IP:3000**

  Services, icons, language and other settings are set in a single config.yml
  ~~~bash
  nano /opt/mafl/data/config.yml
  ~~~`
   
  },
  {
    "category": "Dashboards",

    "title": "Homer LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/bastienwirtz/homer/main/public/assets/icons/logo.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> Homer LXC </h1>
  
  <a href="https://github.com/bastienwirtz/homer#---------homer" target="_blank" rel="noopener noreferrer">Homer</a> is a simple and lightweight static homepage generator that allows you to create and manage a home page for your server. It uses a YAML configuration file to define the layout and content of your homepage, making it easy to set up and customize. The generated homepage is static, meaning it does not require any server-side processing, making it fast and efficient to serve. Homer is designed to be a flexible and low-maintenance solution for organizing and accessing your services and information from a single, centralized location.
  
  To create a new Proxmox VE Homer LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Homer, run the command below (or type update) in the <b>LXC Console</b>.
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/homer.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  512MiB RAM - 2GB Storage - 1vCPU ⚡</h3>

  **Homer Interface: IP:8010**
  
  ⚙️ **Config Path**
  
  ~~~yaml
  /opt/homer/assets/config.yml
  ~~~`
   
  },
  {
    "category": "Dashboards",

    "title": "Dashy LXC",
    "content": `<p align="center"><img src="https://github.com/Lissy93/dashy/raw/master/public/web-icons/dashy-logo.png" height="100"/></p>
  
  <h1 align="center" id="heading"> Dashy LXC </h1>
  
  <a href="https://dashy.to/" target="_blank" rel="noopener noreferrer">Dashy</a> is a solution that helps you organize your self-hosted services by centralizing access to them through a single interface.
  
  To create a new Proxmox VE Dashy LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Dashy, run the command below (or type update) in the <b>LXC Console</b>.
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/dashy.sh)"
  ~~~
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 6GB Storage - 2vCPU ⚡</h3>
  
  **Dashy Interface: IP:4000**`
  },










/* File - Code */
  {
    "category": "File - Code",

    "title": "File Browser",
    "content": `<p align="center"><img src="https://github.com/tteck/Proxmox/blob/main/misc/images/filebrowser.png?raw=true" height="100"/></p>
  
  <h1 align="center" id="heading"> File Browser </h1>

  <a href="https://filebrowser.org/features" target="_blank" rel="noopener noreferrer">File Browser</a> offers a user-friendly web interface for managing files within a designated directory. It allows you to perform various actions such as uploading, deleting, previewing, renaming, and editing files.
  
  To Install, or uninstall File Browser, ⚠️ run the command below in the <b>LXC console</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/misc/filebrowser.sh)"
  ~~~
  
  **File Browser Interface: IP:8080**
  
  ⚙️ **Initial Login (not required for No Authentication)**
  
  **username** <code class="highlighter-rouge">admin</code>

  **password** <code class="highlighter-rouge">changeme</code>
  
  ⚙️ **To Update File Browser**
  
  ~~~yaml
  curl -fsSL https://raw.githubusercontent.com/filebrowser/get/master/get.sh | bash
  ~~~`
  },
  {
    "category": "File - Code",

    "title": "SFTPGo LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/drakkan/sftpgo/main/img/logo.png" height="100"/></p>
  
  <h1 align="center" id="heading"> SFTPGo LXC </h1>

  <a href="https://github.com/drakkan/sftpgo" target="_blank" rel="noopener noreferrer">SFTPGo</a> is a fully featured and highly configurable SFTP server with optional HTTP/S, FTP/S and WebDAV support. Several storage backends are supported: local filesystem, encrypted local filesystem, S3 (compatible) Object Storage, Google Cloud Storage, Azure Blob Storage, SFTP.
  
  To create a new Proxmox VE SFTPGo LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/sftpgo.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 4GB Storage - 2vCPU ⚡</h3>

  **SFTPGo Interface: IP:8080/web/admin**`
  },
  {
    "category": "File - Code",

    "title": "VS Code Server",
    "content": `<p align="center"><img src="https://user-images.githubusercontent.com/674621/71187801-14e60a80-2280-11ea-94c9-e56576f76baf.png?raw=true" height="100"/></p>
  
  <h1 align="center" id="heading"> VS Code Server </h1>

  <a href="https://code.visualstudio.com/docs/remote/vscode-server" target="_blank" rel="noopener noreferrer">VS Code Server</a> is a service you can run on a remote development machine, like your desktop PC or a virtual machine (VM). It allows you to securely connect to that remote machine from anywhere through a vscode.dev URL, without the requirement of SSH.
  
  To Install VS Code Server, ⚠️ run the command below in the <b>LXC console</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/misc/code-server.sh)"
  ~~~
  
  **VS Code Server Interface: IP:8680**`
  },
  {
    "category": "File - Code",

    "title": "Deluge LXC",
    "content": `<p align="center"><img src="https://dev.deluge-torrent.org/chrome/common/deluge_logo.png" height="100"/></p>
  
  <h1 align="center" id="heading"> Deluge LXC </h1>

  <a href="https://www.deluge-torrent.org/" target="_blank" rel="noopener noreferrer">Deluge</a> is a free, open-source, lightweight BitTorrent client. It supports various platforms including Windows, Linux, and macOS, and offers features such as peer exchange, DHT, and magnet links.
  
  To create a new Proxmox VE Deluge LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/deluge.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 4GB Storage - 2vCPU ⚡</h3>

  ⚙️ **Initial Login**

  **password** <code class="highlighter-rouge">deluge</code>

  **Deluge Interface: IP:8112**`
  },
  {
    "category": "File - Code",

    "title": "Forgejo LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/forgejo.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> Forgejo LXC </h1>

  <a href="https://forgejo.org/" target="_blank" rel="noopener noreferrer">Forgejo</a> is an open-source, self-hosted Git service that allows individuals and teams to manage their code repositories.
  
  To create a new Proxmox VE Forgejo LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Forgejo, run the command below (or type update) in the LXC Console.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/forgejo.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 10GB Storage - 2vCPU ⚡</h3>

  **Forgejo Interface: IP:3000**`
  },
  {
    "category": "File - Code",

    "title": "Transmission LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/transmission/transmission/main/web/assets/img/logo.png" height="100"/></p>
  
  <h1 align="center" id="heading"> Transmission LXC </h1>

  <a href="https://transmissionbt.com/" target="_blank" rel="noopener noreferrer">Transmission</a> is a free, open-source BitTorrent client known for its fast download speeds and ease of use. It supports various platforms such as Windows, Linux, and macOS and has features like web interface, peer exchange, and encrypted transfers.
  
  To create a new Proxmox VE Transmission LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/transmission.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 8GB Storage - 2vCPU ⚡</h3>

  ⚙️ **Initial Login**

  **user/password** <code class="highlighter-rouge">transmission</code>

  **Transmission Interface: IP:9091/transmission**`
  },
  {
    "category": "File - Code",

    "title": "Autobrr LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/autobrr/autobrr/master/.github/images/logo.png" height="100"/></p>
  
  <h1 align="center" id="heading"> Autobrr LXC </h1>

  <a href="https://autobrr.com/" target="_blank" rel="noopener noreferrer">Autobrr</a> is a torrent downloading tool that automates the process of downloading torrents. It is designed to be modern and user-friendly, providing users with a convenient and efficient way to download torrent files. With Autobrr, you can schedule and manage your torrent downloads, and have the ability to automatically download torrents based on certain conditions, such as time of day or availability of seeds. This can save you time and effort, allowing you to focus on other tasks while your torrents are being downloaded in the background.
  
  To create a new Proxmox VE Autobrr LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Autobrr, run the command below (or type update) in the <b>LXC Console</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/autobrr.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 8GB Storage - 2vCPU ⚡</h3>

  **Autobrr Interface: IP:7474**`
  },
  {
    "category": "File - Code",

    "title": "PairDrop LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/pairdrop.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> PairDrop LXC </h1>

  <a href="https://github.com/schlagmichdoch/PairDrop" target="_blank" rel="noopener noreferrer">PairDrop:</a> Local file sharing in your browser.
  
  To create a new Proxmox VE PairDrop LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update PairDrop, run the command below (or type update) in the <b>LXC Console</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/pairdrop.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  512MiB RAM - 4GB Storage - 1vCPU ⚡</h3>

  **PairDrop Interface: IP:3000**`
  },
  {
    "category": "File - Code",

    "title": "Aria2 LXC",
    "content": `<p align="center"><img src="" height="100"/></p>
  
  <h1 align="center" id="heading"> Aria2 LXC </h1>
  
  <h3 align="center" id="heading">Option to add AriaNG (GUI)</h3>

  <a href="https://aria2.github.io/" target="_blank" rel="noopener noreferrer">Aria2</a> is a lightweight multi-protocol & multi-source, cross platform download utility operated in command-line. It supports HTTP/HTTPS, FTP, SFTP, BitTorrent and Metalink.
  
  To create a new Proxmox VE Aria2 LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/aria2.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 8GB Storage - 2vCPU ⚡</h3>

  **AriaNG Interface: IP:6880**
  
  Within the LXC console, run <code class="highlighter-rouge">cat rpc.secret</code> to display the rpc-secret. Copy this token and paste it into the Aria2 RPC Secret Token box within the AriaNG Settings. Then, click the reload AriaNG button.`
  },
  {
    "category": "File - Code",

    "title": "qBittorrent LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/qbittorrent/qBittorrent/master/src/icons/qbittorrent.ico" height="100"/></p>
  
  <h1 align="center" id="heading"> qBittorrent LXC </h1>

  <a href="https://www.qbittorrent.org/" target="_blank" rel="noopener noreferrer">qBittorrent</a> offers a user-friendly interface that allows users to search for and download torrent files easily. It also supports magnet links, which allow users to start downloading files without the need for a torrent file.
  
  To create a new Proxmox VE qBittorrent LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/qbittorrent.sh)"
  ~~~

  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 8GB Storage - 2vCPU ⚡</h3>  
  
  **qBittorrent Interface: IP:8090**
  
  ⚙️ **Initial Login**
  
  **username** <code class="highlighter-rouge">admin</code>
  
  **password** <code class="highlighter-rouge">changeme</code>`
  },
  {
    "category": "File - Code",

    "title": "Real-Debrid Torrent Client LXC",
    "content": `<p align="center"><img src="https://fcdn.real-debrid.com/0820/images/logo.png" height="100"/></p>
  
  <h1 align="center" id="heading"> Real-Debrid Torrent Client LXC </h1>

  <a href="https://github.com/rogerfar/rdt-client" target="_blank" rel="noopener noreferrer">RDTClient</a> is a web interface to manage your torrents on Real-Debrid, AllDebrid or Premiumize.
  
  To create a new Proxmox VE Real-Debrid Torrent Client LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Real-Debrid Torrent Client, run the command below (or type update) in the <b>LXC Console</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/rdtclient.sh)"
  ~~~

  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 4GB Storage - 1vCPU ⚡</h3>  
  
  **Real-Debrid Torrent Client Interface: IP:6500**`  
  },
  {
    "category": "File - Code",

    "title": "Jackett LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/Jackett/Jackett/master/src/Jackett.Common/Content/jacket_medium.png" height="100"/></p>
  
  <h1 align="center" id="heading"> Jackett LXC </h1>

  <a href="https://github.com/Jackett/Jackett" target="_blank" rel="noopener noreferrer">Jackett</a> supports a wide range of trackers, including popular ones like The Pirate Bay, RARBG, and Torrentz2, as well as many private trackers. It can be integrated with several BitTorrent clients, including qBittorrent, Deluge, and uTorrent, among others.
  
  To create a new Proxmox VE Jackett LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/jackett.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings: 512MiB RAM - 2GB Storage - 1vCPU ⚡</h3>

  **Jackett Interface: IP:9117**`
  },
  {
    "category": "File - Code",

    "title": "Pingvin Share LXC",
    "content": `<p align="center"><img src="https://github.com/stonith404/pingvin-share/blob/main/frontend/public/img/logo.png?raw=true" height="100"/></p>
  
  <h1 align="center" id="heading"> Pingvin Share LXC </h1>

  <a href="https://github.com/stonith404/pingvin-share" target="_blank" rel="noopener noreferrer">Pingvin Share</a> is self-hosted file sharing platform and an alternative for WeTransfer.
  
  To create a new Proxmox VE Pingvin Share LXC, run the command below in the <b>Proxmox VE Shell</b>.<br>
  To Update Pingvin Share, run the command below (or type update) in the <b>LXC Console</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/pingvin.sh)"
  ~~~

  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 8GB Storage - 2vCPU ⚡</h3>  
  
  **Pingvin Share Interface: IP:3000**`

  },
  {
    "category": "File - Code",

    "title": "Gitea LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/gitea.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> Gitea LXC </h1>

  <a href="https://about.gitea.com/" target="_blank" rel="noopener noreferrer">Gitea</a> is a self-hosted software development service that supports Git repositories, code review, CI/CD, project management and more. It offers flexible deployment options, compatibility with GitHub Actions, and integrations with various tools and databases.
  
  To create a new Proxmox VE Gitea LXC, run the command below in the <b>Proxmox VE Shell</b>.<br>
  To Update Gitea, run the command below (or type update) in the <b>LXC Console</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/gitea.sh)"
  ~~~

  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 8GB Storage - 1vCPU - SQLite3 ⚡</h3>  
  
  **Gitea Interface: IP:3000**`

  },
  {
    "category": "File - Code",

    "title": "Gokapi LXC",
    "content": `
  
  <h1 align="center" id="heading"> Gokapi LXC </h1>

  <a href="https://github.com/Forceu/Gokapi" target="_blank" rel="noopener noreferrer">Gokapi</a> is a lightweight server to share files, which expire after a set amount of downloads or days.
  
  To create a new Proxmox VE Gokapi LXC, run the command below in the <b>Proxmox VE Shell</b>.<br>
  To Update Gokapi, run the command below (or type update) in the <b>LXC Console</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/gokapi.sh)"
  ~~~

  <h3 align="center" id="heading">⚡ Default Settings:  512MiB RAM - 4GB Storage - 1vCPU ⚡</h3>  
  
  **Gokapi Interface: IP:53842/setup**`

  },
  {
    "category": "File - Code",

    "title": "SABnzbd LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/sabnzbd/sabnzbd/develop/icons/logo-arrow.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> SABnzbd LXC </h1>

  <a href="https://sabnzbd.org/" target="_blank" rel="noopener noreferrer">SABnzbd</a> is a free, open-source software program for downloading binary files from Usenet newsgroups. It is designed to be easy to use, and provides a number of features to simplify the downloading process, such as automatic error detection and repair, download scheduling, and integration with other applications. SABnzbd is a binary newsreader, which means it is specifically designed for downloading binary files, such as images, music, and video, from Usenet newsgroups. With its user-friendly interface and powerful features, SABnzbd makes it easy to manage your Usenet downloads and keep your download queue organized.
  
  To create a new Proxmox VE SABnzbd LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update SABnzbd, run the command below (or type update) in the <b>LXC Console</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/sabnzbd.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 8GB Storage - 2vCPU ⚡</h3>

  **SABnzbd Interface: IP:7777**`
  },
  {
    "category": "File - Code",

    "title": "Webmin System Administration",
    "content": `<p align="center"><img src="https://user-images.githubusercontent.com/4426533/218263860-f7baf9d6-cb19-4ddc-86dc-ac1b7a3c3a8a.png?raw=true" height="100"/></p>
  
  <h1 align="center" id="heading"> Webmin System Administration </h1>
  
  <a href="https://webmin.com/" target="_blank" rel="noopener noreferrer">Webmin</a> provides a graphical user interface (GUI) for tasks such as user account management, package management, file system configuration, network configuration, and more.
 
  To Install Webmin System Administration, ⚠️ run the command below in the <b>LXC console</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/misc/webmin.sh)"
  ~~~
  
  **Webmin Interface: (https)IP:10000**
  
  ⚙️ **Initial Login**
  
  **username** <code class="highlighter-rouge">root</code>
  
  **password** <code class="highlighter-rouge">root</code>
  
  ⚙️ **To Update Webmin**
  
  <code class="highlighter-rouge">Update from the Webmin UI</code>
  
  ⚙️ **To Uninstall Webmin**
  
  ~~~yaml
  bash /etc/webmin/uninstall.sh
  ~~~`
  },
  {
    "category": "File - Code",

    "title": "Syncthing LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/syncthing/syncthing/6afaa9f20c8eb9c7af5abbe2f2d90fa2571aa7ad/assets/logo-only.svg?raw=true" height="100"/></p>
  
  <h1 align="center" id="heading"> Syncthing LXC </h1>
  
  <a href="https://syncthing.net/" target="_blank" rel="noopener noreferrer">Syncthing</a> is an open-source file syncing tool that allows users to keep their files in sync across multiple devices by using peer-to-peer synchronization. It doesn't rely on any central server, so all data transfers are directly between devices.
  
  To create a new Proxmox VE Syncthing LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/syncthing.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 8GB Storage - 2vCPU ⚡</h3>
  
  **Syncthing Interface: IP:8384**`
  
  },
  {
    "category": "File - Code",

    "title": "Daemon Sync Server LXC",
    "content": `<p align="center"><img src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimg.informer.com%2Ficons_mac%2Fpng%2F128%2F350%2F350335.png&f=1&nofb=1" height="100"/></p>
  
  <h1 align="center" id="heading"> Daemon Sync Server LXC </h1>
  
  Sync files from app to server, share photos & videos, back up your data and stay secure inside local network.
  
  To create a new Proxmox VE Daemon Sync Server LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/daemonsync.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  512MiB RAM - 8GB Storage - 1vCPU ⚡</h3>
  
  **Daemon Sync Server Interface: IP:8084**
  
  Search: <code class="highlighter-rouge">DAEMON Sync</code> in your favorite app store`
  },
  {
    "category": "File - Code",

    "title": "Kubo LXC",
    "content": `<p align="center"><img src="https://user-images.githubusercontent.com/157609/250148884-d6d12db8-fdcf-4be3-8546-2550b69845d8.png" height="100"/></p>
  
  <h1 align="center" id="heading"> Kubo LXC </h1>
  
  <a href="https://github.com/ipfs/kubo" target="_blank" rel="noopener noreferrer">Kubo</a> was the first IPFS implementation and is the most widely used one today. Implementing the Interplanetary Filesystem - the Web3 standard for content-addressing, interoperable with HTTP. Thus powered by IPLD's data models and the libp2p for network communication. Kubo is written in Go.
  
  To create a new Proxmox VE Kubo LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update kubo, run the command below (or type update) in the <b>LXC Console</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/kubo.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  4GB RAM - 4GB Storage - 2vCPU ⚡</h3>
  
  **Kubo Interface: IP:5001/webui**`
  },









/* Miscellaneous */
  {
    "category": "Miscellaneous",

    "title": "Tandoor Recipes LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/tandoor.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> Tandoor Recipes LXC </h1>
  
  <a href="https://tandoor.dev/" target="_blank" rel="noopener noreferrer">Tandoor Recipes</a> is an application for managing recipes, planning meals, building shopping lists and much much more!
  
  To create a new Proxmox VE Tandoor Recipes LXC, run the following in the <b>Proxmox VE Shell</b>.<br>
  To Update Tandoor Recipes, run the command below (or type update) in the <b>LXC Console</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/tandoor.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings: 2GB RAM - 10GB Storage - 2vCPU ⚡</h3>
  
  **Tandoor Recipes Interface - IP:8002**`
  
  },
  {
    "category": "Miscellaneous",

    "title": "FlowiseAI LXC",
    "content": `<p align="center"><img src="https://flowiseai.com/_ipx/w_256,q_75/_next/static/media/logo-color-high.e60de2f8.png
    " height="100"/></p>
  
  <h1 align="center" id="heading"> FlowiseAI LXC </h1>
  
  <a href="https://flowiseai.com/" target="_blank" rel="noopener noreferrer">FlowiseAI</a> is an open source low-code tool for developers to build customized LLM orchestration flow & AI agents
  
  To create a new Proxmox VE FlowiseAI LXC, run the following in the <b>Proxmox VE Shell</b>.<br>
  To Update FlowiseAI, run the command below (or type update) in the <b>LXC Console</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/flowiseai.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings: 2GB RAM - 10GB Storage - 2vCPU ⚡</h3>
  
  **FlowiseAI Interface - IP:3000**`
  
  },
  {
    "category": "Miscellaneous",

    "title": "OctoPrint LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/octoprint.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> OctoPrint LXC </h1>
  
  <a href="https://octoprint.org/" target="_blank" rel="noopener noreferrer">OctoPrint</a> is a free and open-source web-based 3D printer control software that allows you to remotely control and monitor your 3D printer from a web interface. It was designed to be compatible with a wide range of 3D printers.
  
  To create a new Proxmox VE OctoPrint LXC, run the following in the <b>Proxmox VE Shell</b>.<br>
  To Update OctoPrint, run the command below (or type update) in the <b>LXC Console</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/octoprint.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings: 1GB RAM - 4GB Storage - 1vCPU - Privileged ⚡</h3>
  
  **OctoPrint Interface - IP:5000**`
  
  },
  {
    "category": "Miscellaneous",

    "title": "Spoolman LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/Donkie/Spoolman/master/client/public/favicon.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> Spoolman LXC </h1>
  
  <a href="https://github.com/Donkie/Spoolman" target="_blank" rel="noopener noreferrer">Spoolman</a> is a self-hosted web service designed to help you efficiently manage your 3D printer filament spools and monitor their usage.
  
  To create a new Proxmox VE Spoolman LXC, run the following in the <b>Proxmox VE Shell</b>.<br>
  To Update Spoolman, run the command below (or type update) in the <b>LXC Console</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/spoolman.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings: 1GB RAM - 4GB Storage - 1vCPU ⚡</h3>
  
  **Spoolman Interface - IP:7912**`
  
  },
  {
    "category": "Miscellaneous",

    "title": "Vaultwarden LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/dani-garcia/vaultwarden/main/resources/vaultwarden-icon-white.svg" width="100" height="100"/></p>
  
  <h1 align="center" id="heading"> Vaultwarden LXC </h1>
  
  <a href="https://www.vaultwarden.net/" target="_blank" rel="noopener noreferrer">Vaultwarden</a> is a self-hosted password manager which provides secure and encrypted password storage. It uses client-side encryption and provides access to passwords through a web interface and mobile apps.
  
  To create a new Proxmox VE Vaultwarden LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Vaultwarden, Web-Vault or Set the Admin Token, run the command below (or type update) in the <b>LXC Console</b>.
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/vaultwarden.sh)"
  ~~~
  <h4>⚠️ Vaultwarden needs to be behind a proxy (Nginx Proxy Manager) to obtain HTTPS and to allow clients to connect.</h4>
  
  The script builds from source, which takes time and resources. After the build, the script will automatically set resources to Normal Settings. 
  
  <h3 align="center" id="heading">⚡ Build Settings:  4GB RAM - 6GB Storage - 4vCPU ⚡</h3>
  <h3 align="center" id="heading">⚡ Normal Settings:  512Mib RAM - 6GB Storage - 1vCPU ⚡</h3>

  As an alternative option, you can use Alpine Linux and the Vaultwarden package to create a Vaultwarden LXC container with significantly faster creation time and minimal system resource usage. <br>
  
  To create a new Proxmox VE Alpine-Vaultwarden LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Alpine-Vaultwarden, or Set the Admin Token, run the command below in the <b>LXC Console</b>.
  ~~~bash
  bash -c "$(wget -qO - https://github.com/tteck/Proxmox/raw/main/ct/alpine-vaultwarden.sh)"
  ~~~

  <h3 align="center" id="heading">⚡ Default Settings:  256Mib RAM - 300MiB Storage - 1vCPU ⚡</h3>

  **Vaultwarden Interface: IP:8000**
  
  **Vaultwarden Admin Interface: IP:8000/admin**`
  
  },
  {
    "category": "Miscellaneous",
  
    "title": "Actual Budget LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/actualbudget/actual/master/packages/desktop-client/public/maskable-512x512.png" height="100"/></p>
  
   <h1 align="center" id="heading"> Actual Budget LXC </h1>
  
   <a href="https://actualbudget.org/" target="_blank" rel="noopener noreferrer">Actual Budget</a> is a super fast and privacy-focused app for managing your finances. At its heart is the well proven and much loved Envelope Budgeting methodology.
  
   To create a new Proxmox VE Actual Budget LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
   To Update Actual Budget, run the command below (or type update) in the <b>LXC Console</b>.
  
   ~~~bash
   bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/actualbudget.sh)"
   ~~~

   <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 4GB Storage - 1vCPU ⚡</h3>
  
   **Actual Budget Interface: IP:5006** <a href="https://github.com/tteck/Proxmox/discussions/2727" target="_blank" rel="noopener noreferrer">More Info</a>`
  
  },
  {
    "category": "Miscellaneous",

    "title": "CommaFeed LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/Athou/commafeed/master/commafeed-client/public/app-icon-144.png" height="100"/></p>
  
  <h1 align="center" id="heading"> CommaFeed LXC </h1>
  
  <a href="https://www.commafeed.com/" target="_blank" rel="noopener noreferrer">CommaFeed</a> is a Google Reader inspired self-hosted RSS reader.
  
  To create a new Proxmox VE CommaFeed LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update CommaFeed, run the command below (or type update) in the <b>LXC Console</b>.
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/commafeed.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 4GB Storage - 2vCPU ⚡</h3>

  **CommaFeed Interface: IP:8082**
  
  ⚙️ **Initial Login**
  
  **username** <code class="highlighter-rouge">admin</code>
  
  **password** <code class="highlighter-rouge">admin</code>`
  
  },
  {
    "category": "Miscellaneous",

    "title": "Traccar LXC",
    "content": `<p align="center"><img src="https://avatars.githubusercontent.com/u/37892282?s=100&v=4" height="100"/></p>
  
  <h1 align="center" id="heading"> Traccar LXC </h1>
  
  <a href="https://www.traccar.org/" target="_blank" rel="noopener noreferrer">Traccar</a> is an open source GPS tracking system. It supports more than 200 GPS protocols and more than 2000 models of GPS tracking devices.
  
  To create a new Proxmox VE Traccar LXC, run the command below in the <b>Proxmox VE Shell</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/traccar.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  1GB RAM - 2GB Storage - 1vCPU ⚡</h3>
  
  **Traccar Interface: IP:8082**`
  },
  {
    "category": "Miscellaneous",

    "title": "Readeck LXC",
    "content": `<p align="center"><img src="https://codeberg.org/readeck/readeck/raw/branch/main/web/media/logo-square.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> Readeck LXC </h1>
  
  <a href="https://readeck.org/" target="_blank" rel="noopener noreferrer">Readeck</a> helps you keep all that web content you’ll want to revisit in an hour, tomorrow, or in 20 years.
  
  To create a new Proxmox VE Readeck LXC, run the following in the <b>Proxmox VE Shell</b>. <br>
  To Update Readeck, run the command below (or type update) in the <b>LXC Console</b>.
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/readeck.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings: 512MiB RAM - 2GB Storage - 1vCPU ⚡</h3>
  
  **Readeck Interface - IP:8000**`
  
  },
  {
    "category": "Miscellaneous",

    "title": "grocy LXC",
    "content": `<p align="center"><img src="https://grocy.info/img/grocy_logo.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> grocy LXC </h1>
  
  <a href="https://grocy.info/" target="_blank" rel="noopener noreferrer">grocy</a> is a web-based self-hosted groceries & household management solution for your home. It helps you keep track of your groceries and household items, manage your shopping list, and keep track of your pantry, recipes, meal plans, and more.
  
  To create a new Proxmox VE grocy LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update grocy, run the command below (or type update) in the <b>LXC Console</b>.
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/grocy.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  512MiB RAM - 2GB Storage - 1vCPU ⚡</h3>
  
  **grocy Interface: IP**
  
  ⚙️ **Initial Login**
  
  **username** <code class="highlighter-rouge">admin</code>
  
  **password** <code class="highlighter-rouge">admin</code>`
  
  },
  {
    "category": "Miscellaneous",

    "title": "MagicMirror Server LXC",
  
    "content": `<p align="center"><img src="https://github.com/MichMich/MagicMirror/raw/master/.github/header.png" height="100"/></p>
  
  <h1 align="center" id="heading"> MagicMirror Server LXC </h1>
  
  <a href="https://docs.magicmirror.builders/" target="_blank" rel="noopener noreferrer">MagicMirror²</a> is a smart mirror software that allows you to build your own personal smart mirror. It uses modular components that you can customize to display information such as the weather, news, calendar, to-do list, and more. The platform is open source, allowing for community contributions and customization.
  
  To create a new MagicMirror Server LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update MagicMirror, run the command below (or type update) in the <b>LXC Console</b>.
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/magicmirror.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings: 512MiB RAM - 3GB Storage - 1vCPU ⚡</h3>
  
  **MagicMirror Interface: IP:8080**
  
  ⚙️ **[Configuration](https://docs.magicmirror.builders/configuration/introduction.html#configuring-your-magicmirror)**
  ~~~yaml
  /opt/magicmirror/config/config.js
  ~~~`

  },
  {
    "category": "Miscellaneous",

    "title": "TasmoAdmin LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/tasmota.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> TasmoAdmin LXC </h1>
  
  <a href="https://github.com/TasmoAdmin/TasmoAdmin#readme" target="_blank" rel="noopener noreferrer">TasmoAdmin</a> is an administrative platform for devices flashed with Tasmota.
  
  To create a new Proxmox VE TasmoAdmin LXC, run the following in the <b>Proxmox VE Shell</b>. <br>
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/tasmoadmin.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings: 512MiB RAM - 2GB Storage - 1vCPU ⚡</h3>
  
  **TasmoAdmin Interface - IP:9999**`
  
  },
  {
    "category": "Miscellaneous",

    "title": "RTSPtoWeb LXC",
    "content": `<p align="center"><img src="https://brands.home-assistant.io/_/rtsp_to_webrtc/logo.png?raw=true" height="100"/></p>
  
  <h1 align="center" id="heading"> RTSPtoWeb LXC </h1>
  
  <a href="https://github.com/deepch/RTSPtoWeb" target="_blank" rel="noopener noreferrer">RTSPtoWeb</a> converts your RTSP streams to formats consumable in a web browser like MSE (Media Source Extensions), WebRTC, or HLS. It's fully native Golang without the use of FFmpeg or GStreamer
  
  To create a new Proxmox VE RTSPtoWeb LXC, run the following in the <b>Proxmox VE Shell</b>. <br>
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/rtsptoweb.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings: 2GB RAM - 4GB Storage - 2vCPU ⚡</h3>
  
  **RSTPtoWEB Interface - IP:8083**`
  
  },
  {
    "category": "Miscellaneous",

    "title": "go2rtc LXC",
    "content": `<p align="center"><img src="https://github.com/AlexxIT/go2rtc/blob/master/assets/logo.png?raw=true" height="100"/></p>
  
  <h1 align="center" id="heading"> go2rtc LXC </h1>
  
  <a href="https://github.com/AlexxIT/go2rtc" target="_blank" rel="noopener noreferrer">go2rtc</a> is the ultimate camera streaming application with support RTSP, WebRTC, HomeKit, FFmpeg, RTMP, etc.
  
  To create a new Proxmox VE go2rtc LXC, run the following in the <b>Proxmox VE Shell</b>. <br>
  To Update go2rtc, run the command below (or type update) in the <b>LXC Console</b>.
  
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/go2rtc.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings: 2GB RAM - 4GB Storage - 2vCPU ⚡</h3>
  
  **go2rtc Interface - IP:1984**`
  
  },
  {
    "category": "Miscellaneous",

    "title": "Gotify LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/gotify.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> Gotify LXC </h1>
  
  <a href="https://gotify.net/" target="_blank" rel="noopener noreferrer">Gotify</a> is a simple server for sending and receiving messages
  
  To create a new Proxmox VE Gotify LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>

  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/gotify.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  512MiB RAM - 2GB Storage - 1vCPU ⚡</h3>

  **Gotify Interface: IP**

  ⚙️ **Initial Login**
  
  **username** <code class="highlighter-rouge">admin</code>
  
  **password** <code class="highlighter-rouge">admin</code>`
  
  },
  {
    "category": "Miscellaneous",

    "title": "ntfy LXC",
    "content": `<p align="center"><img src="https://raw.githubusercontent.com/loganmarchione/homelab-svg-assets/main/assets/ntfy.svg" height="100"/></p>
  
  <h1 align="center" id="heading"> ntfy LXC </h1>
  
  <a href="https://ntfy.sh/" target="_blank" rel="noopener noreferrer">ntfy</a> (pronounced notify) is a simple HTTP-based pub-sub notification service. It allows you to send notifications to your phone or desktop via scripts from any computer, and/or using a REST API. It's infinitely flexible, and 100% free software.
  
  To create a new Proxmox VE ntfy LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>

  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/ntfy.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  512MiB RAM - 2GB Storage - 1vCPU ⚡</h3>

  **ntfy Interface: IP**`
  
  },
  {
    "category": "Miscellaneous",

    "title": "Whoogle LXC",
    "content": `<p align="center"><img src="https://github.com/tteck/Proxmox/blob/main/misc/images/whoogle.png?raw=true" height="100"/></p>
  
  <h1 align="center" id="heading"> Whoogle LXC </h1>
  
  Get Google search results, but without any ads, javascript, AMP links, cookies, or IP address tracking.
  
  To create a new Proxmox VE Whoogle LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
  To Update Whoogle, run the command below (or type update) in the <b>LXC Console</b>.
  ~~~bash
  bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/whoogle.sh)"
  ~~~
  
  <h3 align="center" id="heading">⚡ Default Settings:  512MiB RAM - 2GB Storage - 1vCPU ⚡</h3>

  **Whoogle Interface: IP:5000**`
  
},
{
  "category": "Miscellaneous",

  "title": "Hyperion LXC",
  "content": `<p align="center"><img src="https://github.com/hyperion-project/hyperion.ng/raw/master/doc/logo_dark.png?raw=true" height="100"/></p>

 <h1 align="center" id="heading"> Hyperion LXC </h1>

 Hyperion is an opensource Ambient Lighting implementation. It supports many LED devices and video grabbers.

 To create a new Proxmox VE Hyperion LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
 To Update Hyperion, run the command below (or type update) in the <b>LXC Console</b>.

 ~~~bash
 bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/hyperion.sh)"
 ~~~

 <h3 align="center" id="heading">⚡ Default Settings:  512MiB RAM - 2GB Storage - 1vCPU ⚡</h3>

 **Hyperion Interface: IP:8090**`

},
{
  "category": "Miscellaneous",

  "title": "HyperHDR LXC",
  "content": `<p align="center"><img src="https://raw.githubusercontent.com/awawa-dev/HyperHDR/master/resources/icons/hyperhdr-icon-256px.png" height="100"/></p>

 <h1 align="center" id="heading"> HyperHDR LXC </h1>

 <a href="https://github.com/awawa-dev/HyperHDR" target="_blank" rel="noopener noreferrer">HyperHDR</a> is a highly optimized open source ambient lighting implementation based on modern digital video and audio stream analysis.

 To create a new Proxmox VE HyperHDR LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>

 ~~~bash
 bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/hyperhdr.sh)"
 ~~~

 <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 4GB Storage - 2vCPU - Privileged ⚡</h3>

 **HyperHDR Interface: IP:8090**`

},
{
  "category": "Miscellaneous",

  "title": "Wastebin LXC",
  "content": `<p align="center"><img src="https://raw.githubusercontent.com/matze/wastebin/master/assets/favicon.png?raw=true" height="100"/></p>

 <h1 align="center" id="heading"> Wastebin LXC </h1>

 <a href="https://github.com/matze/wastebin" target="_blank" rel="noopener noreferrer">Wastebin</a> is a minimal pastebin with a design shamelessly copied from bin.

 To create a new Proxmox VE Wastebin LXC, run the command below in the <b>Proxmox VE Shell</b>. <br>
 To Update Wastebin, run the command below (or type update) in the <b>LXC Console</b>.

 ~~~bash
 bash -c "$(wget -qLO - https://github.com/tteck/Proxmox/raw/main/ct/wastebin.sh)"
 ~~~

 <h3 align="center" id="heading">⚡ Default Settings:  2GB RAM - 4GB Storage - 2vCPU ⚡</h3>

 **Wastebin Interface: IP:8088**`

}

  ]
}
  
export default data;
